import React from 'react';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import {
    StyledModal, StyledModalBody, ModalInfo, StyledCloseButton,
} from './styled';

const Modal = ({
    show, onHide, content, extraContent,
}) => (
    <StyledModal show={show} onHide={onHide} centered>
        <ModalHeader>
            <StyledCloseButton onClick={onHide} />
        </ModalHeader>
        <StyledModalBody>
            <ModalInfo>{content}</ModalInfo>
            {extraContent}
        </StyledModalBody>
    </StyledModal>
);

export default Modal;
