import styled from 'styled-components';

export const ExportButton = styled.button`
    background-color: ${({ theme }) => theme.colors.lightGreen};
    color: ${({ theme }) => theme.colors.white};
    border: none;
    border-radius: 10px;
    outline: none;
    width: 80%;
    height: 120px;
    padding-top: 10px;
    margin: 0 auto 30px;
    font-family: 'Baloo Tammudu 2', cursive;
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1;
    white-space: pre-wrap;

    @media (max-width: ${({ theme }) => theme.breakpoints.laptopMax}px) {
        height: 100px;
        font-size: 22px;
    }
`;
