import { createSlice } from '@reduxjs/toolkit';

const disksSlice = createSlice({
    name: 'disks',
    initialState: {
        disks: [],
        loading: true,
        activeDiskId: null,
        query: '',
        language: 'pl',
        dragDiskId: null,
    },
    reducers: {
        addDisk: ({ disks }, { payload: disk }) => { disks.push(disk); },
        fetchDisks: (state) => { state.loading = true; },
        fetchDisksSuccess: (state, { payload: newDisks }) => {
            state.disks = newDisks;
            state.loading = false;
        },
        fetchDisksError: (state) => { state.loading = false; },
        setActiveDiskId: (state, { payload: diskId }) => { state.activeDiskId = diskId; },
        setQuery: (state, { payload: query }) => { state.query = query; },
        setLanguage: (state, { payload: language }) => { state.language = language; },
        setDragDiskId: (state, { payload: diskId }) => { state.dragDiskId = diskId; },
    },
});

export const {
    addDisk,
    fetchDisks,
    fetchDisksSuccess,
    fetchDisksError,
    setActiveDiskId,
    setQuery,
    setLanguage,
    setDragDiskId,
} = disksSlice.actions;

export const selectDisksState = (state) => state.disks;
export const selectDisks = (state) => selectDisksState(state).disks;

export const selectDisksWithQuery = (state) => {
    const { disks, query, language } = selectDisksState(state);
    if (query === '') {
        return disks;
    }
    const getTags = (disk) => disk.translations.find((translation) => translation.language === language).tags;

    return disks.filter((disk) => getTags(disk).includes(query));
};

export const selectLoading = (state) => selectDisksState(state).loading;
export const selectQuery = (state) => selectDisksState(state).query;
export const selectActiveDiskId = (state) => selectDisksState(state).activeDiskId;
export const selectDiskById = (state, diskId) => selectDisks(state).find((disk) => disk.id === diskId);
export const selectActiveDisk = (state) => selectDiskById(state, selectActiveDiskId(state));
export const selectDragDiskId = (state) => selectDisksState(state).dragDiskId;
export const selectDragDisk = (state) => selectDiskById(state, selectDragDiskId(state));

export const selectDisksMainCategories = (state) => {
    const disksCategories = selectDisksWithQuery(state).map(({ mainCategory }) => mainCategory);

    return [...new Set(disksCategories)];
};
export const selectDisksByCategory = (state, category) => (
    selectDisksWithQuery(state).filter(({ mainCategory }) => mainCategory === category)
);
export const selectDisksBySubCategory = (state, category) => (
    selectDisksWithQuery(state).filter(({ subCategory }) => subCategory === category)
);
export const selectDisksSubCategories = (state, category) => {
    const disksSubCategories = selectDisksByCategory(state, category).map(({ subCategory }) => subCategory);
    const filteredCategories = disksSubCategories.filter((category) => category.length > 1);

    return [...new Set(filteredCategories)];
};

export const selectMainCategoriesWithSubCategories = (state) => {
    const categories = selectDisksMainCategories(state);
    const categoriesWithSubCategories = categories.map((category) => {
        const subCategories = selectDisksSubCategories(state, category);

        return { name: category, subCategories };
    });

    return categoriesWithSubCategories;
};

export default disksSlice.reducer;
