import {
    takeLatest,
    call,
    put,
} from 'redux-saga/effects';
import { getDisks } from '../api';

import {
    fetchDisks,
    fetchDisksError,
    fetchDisksSuccess,
} from './disksSlice';

function* fetchDisksHandler() {
    try {
        const disks = yield call(getDisks);
        yield put(fetchDisksSuccess(disks));
    } catch (error) {
        yield call(alert, 'Nie udało się wczytać krążków, spróbuj odświeżyć stronę.');
        yield put(fetchDisksError());
    }
}

export default function* watchFetchDisks() {
    yield takeLatest(fetchDisks.type, fetchDisksHandler);
}
