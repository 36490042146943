import { useSelector } from 'react-redux';
import Export from './Export';
import { selectActiveTab } from '../RightMenu/rightMenuSlice';
import Help from './Help';
import Wrapper from './styled';
import Tasks from './Tasks';
import { EXPORT_TAB_NAME, HELP_TAB_NAME, TASKS_TAB_NAME } from '../RightMenu';

const CollapsingSection = () => {
    const activeTab = useSelector(selectActiveTab);
    const isMenuOpen = activeTab.length > 0;

    return (
        <Wrapper isopen={isMenuOpen}>
            <Tasks isActive={activeTab === TASKS_TAB_NAME} />
            <Export isActive={activeTab === EXPORT_TAB_NAME} />
            <Help isActive={activeTab === HELP_TAB_NAME} />
        </Wrapper>
    );
};

export default CollapsingSection;
