import styled from 'styled-components';

export const StyledCoordinates = styled.div`
    padding: 0 26px 26px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    z-index: 3;
    border-right: 1px solid ${({ theme }) => theme.colors.gray};
`;

export const Text = styled.p`
    font-size: 18px;
    margin: 0 0 0 4px;
    line-height: 1;
    opacity: ${({ isOnBoard }) => (isOnBoard ? '1' : '0.5')};
`;

export const DiskImageContainer = styled.div`
    width: 50px;
    height: 50px;
`;

export const Coordinate = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-row-gap: 8px;
`;

export const Header = styled.h4`
    margin: 0;
    font-size: 1em;
`;

export const HeaderContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    font-size: 24px;
`;

export const Positions = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
