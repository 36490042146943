import createBoardSlice from '../createBoardSlice';

export const miniMatSlice = createBoardSlice({
    name: 'miniMat',
    defaultInitialState: [...new Array(5).fill([...new Array(5).fill(0)])],
});

export const { actions, selectors } = miniMatSlice;

export default miniMatSlice.reducer;
