import styled from 'styled-components';
import { ReactComponent as PlayIcon } from './play.svg';
import { ReactComponent as StopIcon } from './stop.svg';

export const StyledTask = styled.div`
    color: ${({ theme }) => theme.colors.white};
    white-space: pre-wrap;
    margin: 0 0 32px;
`;

export const Title = styled.h4`
    font-size: 22px;
    font-weight: 700;
    line-height: 1;
    margin: 0 0 16px;
`;

export const Description = styled.p`
    margin: 0%;
    font-size: 13px;
    margin: 0 0 32px;
`;

export const SecondDescription = styled(Description)`
    margin-top: 16px;
`;

export const Image = styled.img`
    width: 100%;
    height: auto;
`;

export const StyledPlayIcon = styled(PlayIcon)`
    width: auto;
    height: 1.2em;
    margin-left: 1em;
    fill: currentColor;
`;

export const StyledStopIcon = styled(StopIcon)`
    width: auto;
    height: 1.2em;
    margin-left: 1em;
    fill: currentColor;
`;

export const ImageContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
`;

export const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
`;

export const Button = styled.button`
    margin: 8px 0;
    font-size: 14px;
    font-weight: 700;
    background-color: ${({ theme }) => theme.colors.lightGreen};
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    border: 2px solid ${({ theme }) => theme.colors.lightGreen};
    padding: 4px;
    height: 40px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s, background-color 0.3s;

    &:hover {
        color: ${({ theme }) => theme.colors.lightGreen};
        background-color: ${({ theme }) => theme.colors.white};
    }
`;

export const Link = styled.a`
    margin-left: 4px;
    color: ${({ theme }) => theme.colors.neonGreen};
    font-weight: 700;
    text-decoration: underline;
    transition: color 0.3s;

    &:hover {
        color: ${({ theme }) => theme.colors.lightGreen};
    }
`;
