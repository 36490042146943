import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 50px;
`;

export const Button = styled.button`
    background-color: ${({ theme }) => theme.colors.lightBlue};
    color: ${({ theme }) => theme.colors.white};
    border: none;
    outline: none;
    width: 100px;
    height: 50px;
    line-height: 1;
    font-size: 12px;
    font-weight: 700;
    transition: color 0.3s, background-color 0.3s;

    &:hover {
        color: ${({ theme }) => theme.colors.lightBlue};
        background-color: ${({ theme }) => theme.colors.white};
        border: 1px solid;
    } 
`;

export const StyledInput = styled.input`
    border: none;
    height: 50px;
    padding: 20px;
`;
