import { useSelector } from 'react-redux';
import ReactAudioPlayer from 'react-audio-player';
import { useTranslation } from 'react-i18next';
import {
    Description,
    Image,
    StyledPlayIcon,
    StyledStopIcon,
    StyledTask,
    Title,
    Button,
    ButtonsContainer,
    ImageContainer,
    Link,
    SecondDescription,
} from './styled';
import { selectTaskById } from '../../../../../Common/tasks/tasksSlice';
import { Line } from '../../Line';
import { useTaskActions } from './useTaskActions';
import { usePlayer } from '../../../../../Common/usePlayer';

const Task = ({ taskId }) => {
    const task = useSelector((state) => selectTaskById(state, taskId));
    const {
        translations, imgPath, board, id, audioPath, videoPath,
    } = task;
    const { isAudioOn, playerRef, onPlayerButtonClick } = usePlayer();
    const [onLoadSolution, onLoadTask] = useTaskActions(board, id);
    const { t, i18n } = useTranslation();
    const { language: currentLanguage } = i18n;
    const translation = translations.find(({ language }) => language === currentLanguage);
    // eslint-disable-next-line
   
    const getProductUrl = (taskId) => {
        if ([5, 6, 7].includes(taskId)) {
            return 'https://edu-sense.com/pl/dla-przedszkoli/ksiazka-kodowanie-nie-tylko-na-dywanie';
        }

        return 'https://edu-sense.com/pl/produkty/ksiazka-kodowanie-na-dywanie-obrazki-w-rymowankach-ukryte';
    };

    const getBookName = (taskId) => {
        if ([5, 6, 7].includes(taskId)) {
            return 'od aktywności na macie do Scratcha Juniora';
        }

        return 'obrazki w rymowankach ukryte';
    };

    return (
        <StyledTask>
            <Line />
            <Title>{translation?.title}</Title>
            <Description>{translation?.description}</Description>
            <ImageContainer>
                <Image src={`${process.env.REACT_APP_CND_URL}/${imgPath}`} />
                <ButtonsContainer>
                    <Button type="button" onClick={onLoadTask}>
                        {t('Załaduj zadanie')}
                    </Button>
                    <Button type="button" onClick={onLoadSolution}>
                        {t('Załaduj rozwiązanie')}
                    </Button>
                    {audioPath && (
                        <Button type="button" onClick={onPlayerButtonClick}>
                            {isAudioOn ? t('Zatrzymaj piosenkę') : t('Posłuchaj piosenki')}
                            {isAudioOn ? <StyledStopIcon /> : <StyledPlayIcon />}
                        </Button>
                    )}
                    {videoPath && (
                        <Button as="a" href={videoPath} target="_blank" rel="noopener noreferrer">
                            {t('Zobacz karaoke')}
                        </Button>
                    )}
                    {audioPath && (
                        <ReactAudioPlayer
                            src={`${process.env.REACT_APP_CND_URL}/${audioPath}`}
                            ref={playerRef}
                            volume={0.5}
                            preload="none"
                        />
                    )}
                </ButtonsContainer>
            </ImageContainer>
            <SecondDescription>
                {t('Więcej zadań znajdziesz w książce')}
                <Link href={getProductUrl(taskId)} target="_blank" rel="noopener noreferrer">
                    {t(`Kodowanie na dywanie - ${getBookName(taskId)}`)}
                </Link>
                .
            </SecondDescription>
        </StyledTask>
    );
};

export default Task;
