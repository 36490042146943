import { CloseButton, Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
    &.modal.show .modal-dialog {
        &.modal-content {
            background-color: ${({ theme }) => theme.colors.alabaster};
        }
    }

    @media (max-width: 800px) {
        &.modal.show .modal-dialog {
            width: 96vw;
        }

        &.modal.show .modal-dialog-centered {
            display: block;
            align-items: unset;
            min-height: none;
        }
    }
`;

export const StyledCloseButton = styled(CloseButton)`
    background: transparent url('/close.svg') center/1em auto no-repeat;
    opacity: 1;
`;

export const StyledModalBody = styled(Modal.Body)`
    padding: 24px;

    & canvas {
        position: fixed !important;
    }
`;

export const ModalInfo = styled.p`
    text-align: center;
    font-weight: 800;
    font-size: 16px;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.navyBlue};
`;
