import styled, { css } from 'styled-components';

export const StyledRightMenu = styled.div`
    position: absolute;
    right: 0;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    z-index: 3;
`;

export const RightMenuItem = styled.div`
    width: 110px;
    height: 110px;
    border: 2px solid ${({ theme }) => theme.colors.white};
    border-right-width: 0;
    border-radius: 5px 0 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    right: 0;
    transition: transform 0.3s, background-color 0.3s, color 0.3s;
    transform: translateX(0);

    &:hover {
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.black};
    }

    ${({ isactive }) => isactive && css`
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.blue};
        transition: transform 0.7s, background-color 0.3s, color 0.3s; 
        transform: translateX(-650px);
        border-color: ${({ theme }) => theme.colors.blue};

        &:hover {
            color: ${({ theme }) => theme.colors.lightBlue};
            background-color: ${({ theme }) => theme.colors.blue};
        }
    `};

    &:nth-child(2) {
        top: 130px;
    }

    &:nth-child(3) {
        top: 260px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.laptopMax}px) {
        width: 80px;
        height: 80px;

        &:nth-child(2) {
            top: 90px;
        }

        &:nth-child(3) {
            top: 180px;
        }
    };
`;
