import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import { setHash } from '../../App/RightColumn/RightMenu/rightMenuSlice';
import { resetSolution } from '../tasks/tasksSlice';
import {
    Button, ButtonsContainer, GreenButton, StyledButton, Toggler, TogglerButton, Wrapper,
} from './styled';
import { selectShowTranslation, toggleShowTranslations } from '../translationSlice';

const ClearButton = ({
    clearAction, content, modalContent, gridarea = undefined, showTranslationButton = false,
}) => {
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const onHide = () => setShow(false);
    const onConfirm = () => {
        dispatch(clearAction());
        dispatch(setHash(''));
        dispatch(resetSolution(false));
        setShow(false);
    };
    const { t } = useTranslation();
    const showTranslation = useSelector(selectShowTranslation);

    return (
        <Wrapper gridarea={gridarea} className="secret-div">
            <Button onClick={() => setShow(true)} title={modalContent}>
                {content}
            </Button>
            {showTranslationButton && (
                <TogglerButton type="button" onClick={() => { dispatch(toggleShowTranslations()); }}>
                    {t('Tłumaczenia')}
                    <Toggler showTranslation={showTranslation} />
                </TogglerButton>
            )}
            <Modal
                show={show}
                onHide={onHide}
                content={modalContent}
                extraContent={(
                    <ButtonsContainer>
                        <StyledButton onClick={onHide}>{t('NIE')}</StyledButton>
                        <GreenButton onClick={onConfirm}>{t('TAK')}</GreenButton>
                    </ButtonsContainer>
                )}
            />
        </Wrapper>
    );
};

export default ClearButton;
