import createBoardsSaga from '../createBoardSaga';
import { selectors, actions, MAT_CIRCLES_NAME } from './matCirclesSlice';

function* watchMatCirclesChanges() {
    yield createBoardsSaga({
        selectors,
        actions,
        boardName: MAT_CIRCLES_NAME,
    });
}

export default watchMatCirclesChanges;
