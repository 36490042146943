import { useSelector } from 'react-redux';
import { selectors } from '../../../boards/Mat/matSlice';
import { selectDisks } from '../../../../Common/disks/disksSlice';
import { compareCoordinates } from './compareCoordinates';

export const COLORED_SQUARES_NAME = 'kolorowe kartoniki';
const BOARD_TOP_LABELS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

const useCoordinates = (props) => {
    const mat = useSelector(selectors.selectBoard);
    const disks = useSelector(selectDisks);

    const parseBoardStateToCoordinates = (board, disks) => {
        const getDiskMainCategory = (diskId) => disks.find(({ id }) => id === diskId)?.mainCategory;

        let coordinates = [];
        for (let i = 0; i < 10; i++) {
            for (let j = 0; j < 10; j++) {
                const diskId = board[i][j];
                const isSquare = (id) => getDiskMainCategory(id) === COLORED_SQUARES_NAME;
                if (diskId !== 0 && (props?.allTypesOfDisk || isSquare(diskId))) {
                    if (coordinates.some(({ id }) => id === diskId)) {
                        coordinates = coordinates.map(
                            (coordinate) => (
                                coordinate.id === diskId
                                    ? {
                                        ...coordinate,
                                        positions: [...coordinate.positions, BOARD_TOP_LABELS[j] + (i + 1)],
                                    }
                                    : coordinate
                            ),
                        );
                    } else {
                        coordinates.push({ id: diskId, positions: [BOARD_TOP_LABELS[j] + (i + 1)] });
                    }
                }
            }
        }

        const sortedCoordinates = coordinates.map(
            (coordinate) => ({ ...coordinate, positions: coordinate.positions.sort(compareCoordinates) }),
        );

        return sortedCoordinates;
    };

    const taskBoard = props?.taskBoard;
    if (taskBoard) {
        const taskCoordinates = parseBoardStateToCoordinates(taskBoard, disks);

        const isCoordinateOnBoard = (id, position) => (
            mat[+position.slice(1) - 1][BOARD_TOP_LABELS.indexOf(position[0])] === id
        );

        const mergedCoordinates = taskCoordinates.map((coordinate) => ({
            ...coordinate,
            positions: coordinate.positions.map(
                (state) => ({ position: state, isOnBoard: isCoordinateOnBoard(coordinate.id, state) }),
            ),
        }));

        return mergedCoordinates;
    }

    return parseBoardStateToCoordinates(mat, disks);
};

export default useCoordinates;
