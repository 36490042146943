import styled from 'styled-components';

export const StyledFooter = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    padding: 40px 20px;
    z-index: 5;

    @media (max-width: ${({ theme }) => theme.breakpoints.laptopMax}px) {
        padding: 20px 0;
    };
`;

export const LogoLink = styled.a`
    display: flex;
    color: ${({ theme }) => theme.colors.white};
    transition: color 0.3s;

    &:hover {
        color: ${({ theme }) => theme.colors.lightBlue};
    }
`;
