import { createSlice } from '@reduxjs/toolkit';

const tasksSlice = createSlice({
    name: 'tasks',
    initialState: {
        tasks: [],
        loading: true,
        activeTaskId: null,
        wasSolutionLoaded: false,
    },
    reducers: {
        addTask: ({ tasks }, { payload: task }) => { tasks.push(task); },
        fetchTasks: (state) => { state.loading = true; },
        fetchTasksSuccess: (state, { payload: newTasks }) => {
            state.tasks = newTasks;
            state.loading = false;
        },
        fetchTasksError: (state) => { state.loading = false; },
        setActiveTaskId: (state, { payload: taskId }) => {
            state.activeTaskId = taskId;
        },
        resetActiveTask: (state) => { state.activeTaskId = null; },
        loadSolution: (state) => { state.wasSolutionLoaded = true; },
        resetSolution: (state) => { state.wasSolutionLoaded = false; },
    },
});

export const {
    addTask,
    fetchTasks,
    fetchTasksSuccess,
    fetchTasksError,
    setActiveTaskId,
    resetActiveTask,
    loadSolution,
    resetSolution,
} = tasksSlice.actions;

export const selectTasksState = (state) => state.tasks;
export const selectTasks = (state) => selectTasksState(state).tasks;
export const selectLoading = (state) => selectTasksState(state).loading;
export const selectTaskById = (state, taskId) => selectTasks(state).find((task) => task.id === taskId);
export const selectActiveTaskId = (state) => selectTasksState(state).activeTaskId;
export const selectWasSolutionLoaded = (state) => selectTasksState(state).wasSolutionLoaded;
export const selectActiveTask = (state) => {
    const activeTaskId = selectActiveTaskId(state);
    if (activeTaskId) {
        return selectTaskById(state, activeTaskId);
    }

    return undefined;
};

export default tasksSlice.reducer;
