import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setQuery } from '../../../Common/disks/disksSlice';

const SEARCH_DELAY = 500;

export const useOnInputSearchChange = (setValue) => {
    const timeoutRef = useRef();
    const dispatch = useDispatch();

    return (newValue) => {
        clearTimeout(timeoutRef.current);
        setValue(newValue);

        timeoutRef.current = setTimeout(() => {
            dispatch(setQuery(newValue));
        }, SEARCH_DELAY);
    };
};
