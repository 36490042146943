import styled from 'styled-components';

const StyledCommands = styled.div`
    display: grid;
    grid-template-columns: repeat(10, 10%);
    grid-template-rows: repeat(2, 50%);
    border: 1px solid ${({ theme }) => theme.colors.gray};
    border-width: 0 1px 1px 0;
    grid-area: commands;
`;

export default StyledCommands;
