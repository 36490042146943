import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/translationEN.json';
import cz from './locales/translationCZ.json';
import pl from './locales/translationPL.json';
import ua from './locales/translationUA.json';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            pl, en, cz, ua,
        },
        lng: 'ua',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
