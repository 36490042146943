import { useTranslation } from 'react-i18next';
import DiskImage from '../../../../../Common/DiskImage';
import {
    Coordinate, DiskImageContainer, Header, Positions, StyledCoordinates, Text,
} from './styled';

export const BOARD_COORDINATES_TITLE = 'Współrzędne na macie';
export const TASK_COORDINATES_TITLE = 'Współrzędne zadania';

const Coordinates = ({ coordinates, title, extraContent }) => {
    const { t } = useTranslation();

    return (
        <StyledCoordinates>
            {coordinates.length > 0 && <Header>{t(title)}</Header>}
            {coordinates.map((coordinate, index) => (
                <Coordinate key={`${index}-${coordinate.id}`}>
                    <DiskImageContainer>
                        <DiskImage id={coordinate.id} />
                    </DiskImageContainer>
                    <Positions>
                        {coordinate.positions.map((state) => (
                            <Text
                                key={state?.position || state}
                                isOnBoard={title === BOARD_COORDINATES_TITLE || state?.isOnBoard}
                            >
                                {state?.position || state}
                            </Text>
                        ))}
                    </Positions>
                </Coordinate>
            ))}
            {extraContent}
        </StyledCoordinates>
    );
};

export default Coordinates;
