import { LogoContainer, LogoDescription } from './styled';

const Icon = ({ Image, description, extraMargin = false }) => (
    <LogoContainer>
        <Image width={20} height={20} />
        <LogoDescription extraMargin={extraMargin}>{description}</LogoDescription>
    </LogoContainer>
);

export default Icon;
