import { changeLanguage } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { availableLanguages } from '../../useLanguageFromUrl';
import { ArrowIcon } from '../Accordion/styled';
import {
    Container, Icon, Language, LanguageButton, LanguageSwitcher, StyledLanguageButtons,
} from './styled';

const LanguageButtons = () => {
    const { i18n } = useTranslation();
    const history = useHistory();
    const [show, setShow] = useState(false);
    const ref = useRef();

    const onLanguageChange = (language) => {
        history.push(`/${language}`);
        changeLanguage(language);
        setShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShow(false);
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    return (
        <StyledLanguageButtons>
            <LanguageButton
                onClick={() => { setShow((show) => !show); }}
                iscollapsed={show ? 1 : 0}
            >
                <Icon src={`/images/flags/${i18n.language}.svg`} width={40} height={20} alt="" />
                <Container>
                    <Language>{i18n.language}</Language>
                    <ArrowIcon iscollapsed={1} />
                </Container>
            </LanguageButton>
            <LanguageSwitcher show={show} ref={ref}>
                {availableLanguages.filter((language) => language !== i18n.language).map((language) => (
                    <LanguageButton type="button" key={language} onClick={() => { onLanguageChange(language); }}>
                        <Icon src={`/images/flags/${language}.svg`} width={40} height={20} alt="" />
                        <Language>{language}</Language>
                    </LanguageButton>
                ))}
            </LanguageSwitcher>
        </StyledLanguageButtons>
    );
};

export default LanguageButtons;
