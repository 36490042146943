import styled, { css } from 'styled-components';
import { StyledPlayIcon, StyledStopIcon } from '../../../../RightColumn/CollapsingSection/Tasks/Task/styled';

export const StyledTranslation = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: 12px;
    align-items: center;
    padding: 10px;
    font-size: 14px;

    @media (max-width: 1650px) {
        padding: 4px;
        font-size: 13px;
    }
`;

export const FlagIcon = styled.img`
    width: 30px;
    height: auto;
    border: 1px solid black;

    @media (max-width: 1650px) {
        width: 28px;
    }
`;

const iconStyles = css`
    margin: 0 auto;
    display: block;
`;

export const PlayIcon = styled(StyledPlayIcon)`
    ${iconStyles}
    
    @media (max-width: 1650px) {
        height: 1.1em;
    }
`;

export const StopIcon = styled(StyledStopIcon)`
    ${iconStyles}
    margin-right: 0.3em;
`;

export const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    min-width: 20px;
`;
