import styled from 'styled-components';

export const TasksContainer = styled.section`
    padding: 50px 80px 0;
    color: ${({ theme }) => theme.colors.white};
    height: 85%;
    overflow: auto;
    font-size: 13px;
    display: ${({ isactive }) => (isactive ? 'block' : 'none')};
`;
