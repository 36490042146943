import styled from 'styled-components';
import { ReactComponent as Magnifier } from './magnifier.svg';
import { ReactComponent as Remove } from './remove.svg';

export const Input = styled.input`
    width: 100%;
    padding: 12px;

    &:focus {
        outline: 1px solid ${({ theme }) => theme.colors.black};
    }

    &::placeholder {
        color: ${({ theme }) => theme.colors.black};
        opacity: 1;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.laptopMax}px) {
        padding: 8px;
    };
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 16px;
`;

const iconStyles = `
    fill: ${({ theme }) => theme.colors.black};
    position: absolute;
    width: 20px;
    height: auto;
    right: 12px;

    @media (max-width: ${({ theme }) => theme.breakpoints.laptopMax}px) {
        right: 8px;
    };
`;

export const Icon = styled(Magnifier)`
    ${iconStyles}
`;

export const RemoveIcon = styled(Remove)`
    ${iconStyles}
    transition: fill 0.3s;

    &:hover {
        cursor: pointer;
        fill: ${({ theme }) => theme.colors.red};
    }
`;
