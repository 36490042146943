import createBoardsSaga from '../createBoardSaga';
import { selectors, actions } from './miniMatSlice';

function* watchMiniMatChanges() {
    yield createBoardsSaga({
        selectors,
        actions,
        boardName: 'miniMat',
    });
}

export default watchMiniMatChanges;
