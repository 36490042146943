import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    html {
        box-sizing: border-box;
    }

    *, ::after, ::before {
        box-sizing: inherit;
    }

    body {
        font-family: 'Roboto', sans-serif;        
        margin: 0;
        padding-right: 0 !important;
        overflow-x: hidden;
        min-width: 800px;
        -webkit-overflow-scrolling: touch;

        @media (max-width: 800px) {
            overflow-x: auto;
        }
    }

    a {
        color: inherit;
        text-decoration: none;
    }
`;

export default GlobalStyle;
