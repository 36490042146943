import jsPDF from 'jspdf';
import roboto from './roboto';
import logoKodowanie from './kodowanie_na_dywanie_logo.png';
import logoUdp from './logo_udp_light.png';
import {
    drawFooter, drawHeaderImages, drawLink, setRobotoFont,
} from './drawDocumentsHelper';

const drawBoard = (doc, board, startPoint, cellWidth, imageWidth) => {
    board.forEach((row, rowIndex) => row.forEach((path, columnIndex) => {
        if (path.length > 0) {
            const x = startPoint[0] + 0.25 + columnIndex * cellWidth;
            const y = startPoint[1] + 0.25 + rowIndex * cellWidth;
            doc.addImage(path, 'PNG', x, y, imageWidth, imageWidth);
        }
    }));
    doc.rect(startPoint[0], startPoint[1], 9 * cellWidth, 9 * cellWidth);
};

const createMatCirclesDocument = ({
    hash, link, board, t,
}) => {
    // eslint-disable-next-line
    const doc = new jsPDF();
    setRobotoFont(doc, roboto);
    drawHeaderImages(doc, logoUdp, logoKodowanie);

    if (link) {
        drawLink(doc, link, t);
    }

    const boardStartPosition = [38, 65];
    const cellWidth = 15;
    drawBoard(doc, board, boardStartPosition, cellWidth, 14.5);

    drawFooter(doc);

    doc.save(`${t('wirtualna-mata')}-${!link ? `${t('zadanie')}-` : ''}${hash}.pdf`);
};

export default createMatCirclesDocument;
