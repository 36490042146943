import { useCallback, useState } from 'react';
import * as htmlToImage from 'html-to-image';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectActiveBoard, selectHash } from '../../../RightMenu/rightMenuSlice';
import { ExportButton } from '../ExportButton';

const ImageExportButton = () => {
    const hash = useSelector(selectHash);
    const activeBoard = useSelector(selectActiveBoard);
    const ref = document.querySelector(`#${activeBoard}`);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const filter = (node) => !node?.classList?.value.includes('secret-div');

    const onButtonClick = useCallback(() => {
        setLoading(true);

        htmlToImage.toPng(ref, { filter })
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = `${t('wirtualna-mata')}-${hash}.png`;
                link.href = dataUrl;
                link.click();
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [ref, hash, t]);

    return (
        <ExportButton type="button" onClick={onButtonClick}>
            {loading ? t('Trwa pobieranie obrazka...') : t('Pobierz matę\njako obrazek')}
        </ExportButton>
    );
};

export default ImageExportButton;
