import createBoardsSaga from '../../createBoardSaga';
import { selectors, actions } from './commandsSlice';

function* watchCommandsChanges() {
    yield createBoardsSaga({
        selectors,
        actions,
        boardName: 'commands',
    });
}

export default watchCommandsChanges;
