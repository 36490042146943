import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Modal from '../../../../../Common/Modal';
import { selectActiveTask } from '../../../../../Common/tasks/tasksSlice';
import Confetti from './Confetti';

const CongratsModal = ({ show, setShow }) => {
    const activeTask = useSelector(selectActiveTask);
    const { t, i18n } = useTranslation();

    const translation = activeTask?.translations.find((translation) => translation.language === i18n.language);

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            content={`${t('Gratulacje! Właśnie ukończyłeś zadanie')} "${translation?.title}".`}
            extraContent={<Confetti />}
        />
    );
};

export default CongratsModal;
