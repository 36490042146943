import { useTranslation } from 'react-i18next';
import { LogoLink, StyledFooter } from './styled';
import { ReactComponent as Shop } from './shop.svg';
import { ReactComponent as Udp } from './udp.svg';
import Icon from '../Icon';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <StyledFooter>
            <LogoLink href="https://akademia.uczymydzieciprogramowac.pl" target="_blank">
                <Icon Image={Udp} description={t('AKADEMIA UDP')} />
            </LogoLink>
            <LogoLink href="https://edu-sense.com/pl/produkty" target="_blank">
                <Icon Image={Shop} description={t('SKLEP')} extraMargin />
            </LogoLink>
        </StyledFooter>
    );
};

export default Footer;
