import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MAT_NAME } from '../../../../boards/Mat/matSlice';
import useCoordinates from '../../../../DiskSection/DisksContainer/CoordinatesContainer/useCoordinates';
import { selectActiveBoard } from '../../../RightMenu/rightMenuSlice';

const useExportButtonContent = (emptyBoardExport) => {
    const coordinates = useCoordinates({ allTypesOfDisk: true });
    const activeBoard = useSelector(selectActiveBoard);
    const { t } = useTranslation();

    const coordinatesText = coordinates.length > 0 && activeBoard === MAT_NAME ? t(' ze \n współrzędnymi ') : '\n';

    return `${t('Pobierz')}${emptyBoardExport ? t(' pustą ') : ' '}${t('matę')}${coordinatesText}${t('jako pdf')}`;
};

export default useExportButtonContent;
