import ReactAudioPlayer from 'react-audio-player';
import { usePlayer } from '../../../../../Common/usePlayer';
import {
    FlagIcon, IconContainer, PlayIcon, StopIcon, StyledTranslation,
} from './styled';

const Translation = ({ name, diskId, language }) => {
    const {
        isAudioOn, setIsAudioOn, playerRef, onPlayerButtonClick,
    } = usePlayer();

    const imageSrc = (language) => `/images/flags/${language}.svg`;
    const getSoundSrc = (language) => `${process.env.REACT_APP_CND_URL}/voices/${language}/${diskId}_${language}.mp3`;

    return (
        <StyledTranslation onClick={() => { onPlayerButtonClick(); }}>
            <FlagIcon src={imageSrc(language)} alt="" />
            {name}
            <IconContainer>
                {isAudioOn ? <StopIcon /> : <PlayIcon />}
            </IconContainer>
            <ReactAudioPlayer
                src={getSoundSrc(language)}
                ref={playerRef}
                volume={0.5}
                preload="none"
                onEnded={() => { setIsAudioOn(false); }}
            />
        </StyledTranslation>
    );
};

export default Translation;
