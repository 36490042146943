import { useSelector } from 'react-redux';
import Field from '../../Field';
import { actions, selectors } from './commandsSlice';
import StyledCommands from './styled';

const Commands = () => {
    const commands = useSelector(selectors.selectBoard);

    return (
        <StyledCommands>
            {commands.map((row, rowIndex) => row.map((diskId, columnIndex) => (
                <Field
                    key={`${rowIndex}-${columnIndex}`}
                    setBoardField={actions.setBoardField}
                    resetBoardField={actions.resetBoardField}
                    rowIndex={rowIndex}
                    columnIndex={columnIndex}
                    diskId={diskId}
                />
            )))}
        </StyledCommands>
    );
};

export default Commands;
