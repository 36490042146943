import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDisks, selectMainCategoriesWithSubCategories } from '../../Common/disks/disksSlice';
import Accordion from './Accordion';
import DisksContainer from './DisksContainer';
import Coordinates from './DisksContainer/CoordinatesContainer';
import { COLORED_SQUARES_NAME } from './DisksContainer/CoordinatesContainer/useCoordinates';
import LanguageButtons from './LanguageButtons';
import Search from './Search';
import {
    AllDiskContainer, DiskSectionContainer, Header, StyledLogoPL, StyledLogoEN, StyledLogoCZ, StyledLogoUA,
} from './styled';

const DiskSection = () => {
    const dispatch = useDispatch();
    const categories = useSelector(selectMainCategoriesWithSubCategories);
    const { i18n } = useTranslation();
    const logos = {
        pl: <StyledLogoPL />,
        en: <StyledLogoEN />,
        cz: <StyledLogoCZ />,
        ua: <StyledLogoUA />,
    };

    useEffect(() => {
        dispatch(fetchDisks());
    }, [dispatch]);

    return (
        <DiskSectionContainer>
            <Header>
                {logos[i18n.language]}
                <LanguageButtons />
            </Header>
            <Search />
            <AllDiskContainer>
                {categories.map((category) => (
                    <Accordion
                        key={category.name}
                        title={category.name}
                        content={category.subCategories.length > 0
                            ? category.subCategories.map((subCategory) => (
                                <Accordion
                                    key={subCategory}
                                    title={subCategory}
                                    subCategory
                                    content={<DisksContainer category={subCategory} isSubCategory />}
                                />
                            ))
                            : (
                                <>
                                    <DisksContainer category={category.name} />
                                    {category.name === COLORED_SQUARES_NAME && <Coordinates />}
                                </>
                            )}
                    />
                ))}
            </AllDiskContainer>
        </DiskSectionContainer>
    );
};

export default DiskSection;
