export const getDefaultImages = (extension) => {
    const red = '/cup_cz';
    const lightBlue = '/cup_s';
    const orange = '/cup_p';
    const green = '/cup_z';
    const pink = '/cup_r';
    const grey = '/cup_sz';
    const yellow = '/cup_zo';
    const viloet = '/cup_f';
    const blue = '/cup_n';

    const addCdnPath = (path, extension) => (
        `${process.env.REACT_APP_CND_URL}/krazki_kompetencji/tlo_barwy${path}.${extension}`
    );

    const defaultImages = [
        [red, red, red, lightBlue, lightBlue, lightBlue, orange, orange, orange],
        [red, red, red, lightBlue, lightBlue, lightBlue, orange, orange, orange],
        [red, red, red, lightBlue, lightBlue, lightBlue, orange, orange, orange],
        [green, green, green, pink, pink, pink, grey, grey, grey],
        [green, green, green, pink, pink, pink, grey, grey, grey],
        [green, green, green, pink, pink, pink, grey, grey, grey],
        [yellow, yellow, yellow, viloet, viloet, viloet, blue, blue, blue],
        [yellow, yellow, yellow, viloet, viloet, viloet, blue, blue, blue],
        [yellow, yellow, yellow, viloet, viloet, viloet, blue, blue, blue],
    ];

    return defaultImages.map((row) => row.map((path) => addCdnPath(path, extension)));
};
