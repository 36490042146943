import createBoardSlice from '../createBoardSlice';

export const MAT_NAME = 'mat';

export const matSlice = createBoardSlice({
    name: MAT_NAME,
    defaultInitialState: [...new Array(10).fill([...new Array(10).fill(0)])],
});

export const { actions, selectors } = matSlice;

export default matSlice.reducer;
