import { StyledLabels, Label, LABEL_TOP_NAME } from './styled';

const Labels = ({ position }) => (
    <StyledLabels position={position}>
        {[...Array(10).keys()].map((number) => (
            <Label key={number}>
                {position === LABEL_TOP_NAME ? String.fromCharCode(number + 65) : number + 1}
            </Label>
        ))}
    </StyledLabels>
);

export default Labels;
