import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTasks, selectTasks } from '../../../../Common/tasks/tasksSlice';
import { TasksContainer } from './styled';
import Task from './Task';

const Tasks = ({ isActive }) => {
    const tasks = useSelector(selectTasks);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(fetchTasks());
    }, [dispatch]);

    return (
        <TasksContainer isactive={isActive}>
            <p>{t('zadaniaWstep')}</p>
            {tasks.map((task) => (
                <Task key={task.id} taskId={task.id} />
            ))}
        </TasksContainer>
    );
};

export default Tasks;
