import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDragDisk, setDragDiskId } from '../../../Common/disks/disksSlice';
import { setShow } from '../../Tabs/ActiveDiskModal/modalSlice';

const useDragAndDrop = (setBoardField, resetBoardField, rowIndex, columnIndex, diskId, activeDisk) => {
    const dragDisk = useSelector(selectDragDisk);
    const dispatch = useDispatch();
    const onFieldClick = (row, column) => {
        if (activeDisk === undefined) {
            dispatch(setShow(true));

            return;
        }

        if (activeDisk.id === diskId) {
            dispatch(resetBoardField({ row, column }));

            return;
        }

        dispatch(setBoardField({ row, column, diskId: activeDisk?.id }));
    };

    const [startPosition, setStartPosition] = useState();
    const [currentPosition, setCurrentPosition] = useState();

    const onTouchStart = (e) => {
        dispatch(setDragDiskId(diskId));
        setStartPosition([e.targetTouches[0].pageX, e.targetTouches[0].pageY]);
    };

    const onFieldTouch = (e) => {
        const touchLocation = e.targetTouches[0];
        touchLocation.target.style.position = 'absolute';
        touchLocation.target.style.left = `${touchLocation.pageX - startPosition[0]}px`;
        touchLocation.target.style.top = `${touchLocation.pageY - startPosition[1]}px`;
        touchLocation.target.style.zIndex = 3;
        setCurrentPosition([
            (touchLocation.pageX - startPosition[0]) / e.target.offsetHeight,
            (touchLocation.pageY - startPosition[1]) / e.target.offsetHeight,
        ]);
    };

    const onTouchEnd = (e) => {
        e.target.style.position = 'unset';
        e.target.style.zIndex = 1;
        if (dragDisk === undefined || currentPosition === undefined) {
            return;
        }

        dispatch(setBoardField({
            row: Math.round(rowIndex + currentPosition[1]),
            column: Math.round(columnIndex + currentPosition[0]),
            diskId: dragDisk?.id,
        }));

        dispatch(resetBoardField({ row: rowIndex, column: columnIndex }));
    };

    const onDragEnd = () => { dispatch(resetBoardField({ row: rowIndex, column: columnIndex })); };

    const onDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };
    const onDragStart = () => { dispatch(setDragDiskId(diskId)); };
    const onDrop = () => {
        if (dragDisk === undefined) {
            return;
        }

        dispatch(setBoardField({ row: rowIndex, column: columnIndex, diskId: dragDisk?.id }));
    };

    return {
        onFieldClick,
        onDragStart,
        onDragEnd,
        onDragOver,
        onDrop,
        onTouchStart,
        onFieldTouch,
        onTouchEnd,
    };
};

export default useDragAndDrop;
