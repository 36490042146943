import createBoardSlice from '../createBoardSlice';

export const MAT_CIRCLES_NAME = 'matCircles';

export const matCirclesSlice = createBoardSlice({
    name: MAT_CIRCLES_NAME,
    defaultInitialState: [...new Array(9).fill([...new Array(9).fill(0)])],
});

export const { actions, selectors } = matCirclesSlice;

export default matCirclesSlice.reducer;
