import {
    takeLatest,
    call,
    put,
} from 'redux-saga/effects';
import { postBoard } from '../../../Common/api';
import { MAT_NAME } from '../../boards/Mat/matSlice';
import { fetchPostMat, setHash } from './rightMenuSlice';

function* fetchPostMatHandler({ payload }) {
    try {
        const body = JSON.stringify({
            type: payload.activeBoard,
            data: payload.board,
            commands: payload.activeBoard === MAT_NAME ? payload.commands : null,
        });
        const response = yield call(postBoard, body);
        const hash = response?.hash;

        if (hash) {
            yield put(setHash(hash));
        }
    } catch (error) {
        yield call(alert, error);
    }
}

export function* watchFetchPostMat() {
    yield takeLatest(fetchPostMat.type, fetchPostMatHandler);
}
