import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveTab, setActiveTab } from './RightMenu/rightMenuSlice';

function useOutsideHideMenu(ref) {
    const dispatch = useDispatch();
    const activeTab = useSelector(selectActiveTab);
    const isMenuOpen = activeTab.length > 0;

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) && isMenuOpen) {
                dispatch(setActiveTab(''));
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, isMenuOpen, dispatch]);
}

export default useOutsideHideMenu;
