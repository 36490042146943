import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../Common/Modal';
import { selectShow, setShow } from './modalSlice';

const ActiveDiskModal = () => {
    const show = useSelector(selectShow);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <Modal
            show={show}
            onHide={() => dispatch(setShow(false))}
            content={t('modalPustaMata')}
        />
    );
};

export default ActiveDiskModal;
