import { createSlice } from '@reduxjs/toolkit';

const translationSlice = createSlice({
    name: 'translation',
    initialState: {
        showTranslations: true,
    },
    reducers: {
        toggleShowTranslations(state) {
            state.showTranslations = !state.showTranslations;
        },
    },
});

export const selectShowTranslation = (state) => state.translation.showTranslations;

export const { toggleShowTranslations } = translationSlice.actions;
export default translationSlice.reducer;
