const theme = {
    colors: {
        red: '#FF0000',
        darkGreen: '#076914',
        lightGreen: '#39B54A',
        mantis: '#58ca68',
        neonGreen: '#0eff00',
        navyBlue: '#00437E',
        darkBlue: '#143A67',
        blue: '#005DAE',
        lightBlue: '#00AFFF',
        black: '#000000',
        gray: '#B4B4B4',
        lightGray: '#DDDDDD',
        alabaster: '#F7F7F7',
        white: '#FFFFFF',
    },
    breakpoints: {
        mobileVerticalMax: 575,
        mobileMax: 767,
        tabletVertivalMax: 991,
        tabletHorizontalMax: 1199,
        laptopMax: 1392,
    },
};

export default theme;
