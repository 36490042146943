import jsPDF from 'jspdf';
import roboto from './roboto';
import logoKodowanie from './kodowanie_na_dywanie_logo.png';
import logoUdp from './logo_udp_light.png';
import {
    drawLink, drawHeaderImages, setRobotoFont, drawFooter, drawCoordinatesPage,
} from './drawDocumentsHelper';

const drawBoard = (doc, board, startPosition, cellWidth, imgHeight) => {
    doc.setDrawColor('#777777');
    const boardStart = startPosition.map((position) => position + cellWidth);

    [...Array(10).keys()].forEach((number) => {
        doc.text(
            String.fromCharCode(number + 65),
            startPosition[0] + (number + 1.4) * cellWidth,
            startPosition[1] + 0.6 * cellWidth,
        );
    });
    [...Array(10).keys()].forEach((number) => {
        doc.text(
            (number + 1).toString(),
            startPosition[0] + (number === 9 ? 0.3 : 0.4) * cellWidth,
            startPosition[1] + (number + 1.6) * cellWidth,
        );
    });
    board.forEach((row, rowIndex) => row.forEach((path, columnIndex) => {
        doc.line(
            boardStart[0] + columnIndex * cellWidth,
            boardStart[1] + rowIndex * cellWidth,
            boardStart[0] + (columnIndex + 1) * cellWidth,
            boardStart[1] + rowIndex * cellWidth,
        );
        doc.line(
            boardStart[0] + columnIndex * cellWidth,
            boardStart[1] + rowIndex * cellWidth,
            boardStart[0] + columnIndex * cellWidth,
            boardStart[1] + (rowIndex + 1) * cellWidth,
        );
        if (path.length > 0) {
            const x = boardStart[0] + 0.25 + columnIndex * cellWidth;
            const y = boardStart[1] + 0.25 + rowIndex * cellWidth;
            doc.addImage(path, 'PNG', x, y, imgHeight, imgHeight);
        }
    }));

    doc.line(
        boardStart[0] + 10 * cellWidth,
        boardStart[1],
        boardStart[0] + 10 * cellWidth,
        boardStart[1] + 10 * cellWidth,
    );
    doc.line(
        boardStart[0],
        boardStart[1] + 10 * cellWidth,
        boardStart[0] + 10 * cellWidth,
        boardStart[1] + 10 * cellWidth,
    );
};

const drawRedCross = (doc, boardStart, cellWidth) => {
    doc.setDrawColor('#FF0000');
    doc.setLineWidth(1);
    doc.line(
        boardStart[0], boardStart[1] + 5 * cellWidth, boardStart[0] + 10 * cellWidth, boardStart[1] + 5 * cellWidth,
    );
    doc.line(
        boardStart[0] + 5 * cellWidth, boardStart[1], boardStart[0] + 5 * cellWidth, boardStart[1] + 10 * cellWidth,
    );
};

const drawCoordinatesBoard = (doc, commands, commandsStart, cellWidth, imgHeight) => {
    commands.forEach((row, rowIndex) => row.forEach((path, columnIndex) => {
        doc.line(
            commandsStart[0] + columnIndex * cellWidth,
            commandsStart[1] + rowIndex * cellWidth,
            commandsStart[0] + (columnIndex + 1) * cellWidth,
            commandsStart[1] + rowIndex * cellWidth,
        );
        doc.line(
            commandsStart[0] + columnIndex * cellWidth,
            commandsStart[1] + rowIndex * cellWidth,
            commandsStart[0] + columnIndex * cellWidth,
            commandsStart[1] + (rowIndex + 1) * cellWidth,
        );
        if (path.length > 0) {
            const x = commandsStart[0] + 0.25 + columnIndex * cellWidth;
            const y = commandsStart[1] + 0.25 + rowIndex * cellWidth;
            doc.addImage(path, 'PNG', x, y, imgHeight, imgHeight);
        }
    }));

    doc.line(
        commandsStart[0] + 10 * cellWidth,
        commandsStart[1],
        commandsStart[0] + 10 * cellWidth,
        commandsStart[1] + 2 * cellWidth,
    );
    doc.line(
        commandsStart[0],
        commandsStart[1] + 2 * cellWidth,
        commandsStart[0] + 10 * cellWidth,
        commandsStart[1] + 2 * cellWidth,
    );
};

const createMatDocument = ({
    t, language, hash, link, board, commands, coordinates,
}) => {
    // eslint-disable-next-line
    const doc = new jsPDF();
    setRobotoFont(doc, roboto);

    drawHeaderImages(doc, logoUdp, logoKodowanie);
    drawLink(doc, link, t);

    const cellWidth = 15;
    const imgHeight = 14.5;
    const startPosition = [16, 50];
    drawBoard(doc, board, startPosition, cellWidth, imgHeight);
    const boardStart = startPosition.map((position) => position + cellWidth);

    const commandsStart = [boardStart[0], boardStart[1] + 10.5 * cellWidth];
    drawCoordinatesBoard(doc, commands, commandsStart, cellWidth, imgHeight);

    drawRedCross(doc, boardStart, cellWidth);

    doc.setFontSize(12);
    doc.textWithLink('www.mata.uczymydzieciprogramowac.pl', 68, 265,
        { url: `https://www.mata.uczymydzieciprogramowac.pl/${language}` });
    drawFooter(doc);

    let i = 0;
    const numberOfCoordinates = 12;
    while (i < coordinates.length / 12) {
        drawCoordinatesPage(doc, coordinates.slice(i * numberOfCoordinates, (i + 1) * numberOfCoordinates), t);
        i += 1;
    }

    doc.save(`${t('wirtualna-mata')}-${!link ? `${t('zadanie')}-` : ''}${hash}.pdf`);
};

export default createMatDocument;
