import styled from 'styled-components';

export const StyledLanguageButtons = styled.div`
    position: relative;
`;

export const LanguageButton = styled.button`
    padding: 0;
    border: none;
    outline: none;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    background-color: white;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 8px;
    padding: 4px 8px;
    align-items: center;
    text-align: left;
    
    &:hover {
        background-color: #dddddd;
    }
`;

export const LanguageSwitcher = styled.div`
    position: absolute;
    z-index: 10;
    grid-template-columns: 1fr;
    align-items: center;
    display: ${({ show }) => (show ? 'grid' : 'none')};
    border: 1px solid #B4B4B4;
    width: 100%;
`;

export const Icon = styled.img`
    width: 30px;
    height: auto;
    border: 1px solid black;
`;

export const Container = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 8px;
    align-items: center;
`;

export const Language = styled.span`
    padding-top: 2px;
`;
