import { useDispatch } from 'react-redux';
import { loadSolution, setActiveTaskId } from '../../../../../Common/tasks/tasksSlice';
import { actions, MAT_NAME } from '../../../../boards/Mat/matSlice';
import { setActiveBoard, setActiveTab } from '../../../RightMenu/rightMenuSlice';

export const useTaskActions = (board, id) => {
    const dispatch = useDispatch();

    const onLoadSolution = () => {
        dispatch(actions.setBoardState(board));
        dispatch(loadSolution());
        dispatch(setActiveTab(''));
    };

    const onLoadTask = () => {
        dispatch(setActiveTaskId(id));
        dispatch(setActiveTab(''));
        dispatch(setActiveBoard(MAT_NAME));
    };

    return [onLoadSolution, onLoadTask];
};
