import { useRef } from 'react';

export const useInputActions = (url) => {
    const inputRef = useRef();

    const onInputFocus = (e) => {
        e.target.select();
        copyTextToClipboard(url);
    };

    const copyTextToClipboard = async (text) => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(text);

            return;
        }
        document.execCommand('copy', true, text);
    };

    return [inputRef, onInputFocus, copyTextToClipboard];
};
