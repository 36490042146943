import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectQuery } from '../../../Common/disks/disksSlice';
import { selectActiveTaskId } from '../../../Common/tasks/tasksSlice';
import { COLORED_SQUARES_NAME } from '../DisksContainer/CoordinatesContainer/useCoordinates';

export const useCollapsed = (title) => {
    const isQueryEmpty = useSelector(selectQuery) === '';
    const [isCollapsed, setIsCollapsed] = useState(isQueryEmpty);
    const activeTaskId = useSelector(selectActiveTaskId);

    useEffect(() => {
        if (activeTaskId && title === COLORED_SQUARES_NAME) {
            setIsCollapsed(false);
        }
    }, [activeTaskId, title]);

    useEffect(() => {
        setIsCollapsed(isQueryEmpty);
    }, [isQueryEmpty]);

    return [isCollapsed, setIsCollapsed];
};
