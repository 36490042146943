import { useTranslation } from 'react-i18next';
import { Line } from '../Line';
import {
    Header, List, Paragraph, StyledHelp,
} from './styled';

const Help = ({ isActive }) => {
    const { t } = useTranslation();

    return (
        <StyledHelp isactive={isActive}>
            <Header>{t('Mata do kodowania - siatka 10 na 10')}</Header>
            <Paragraph>{t('pomocPierwszyParagraf')}</Paragraph>
            <List>
                <li>{t('kolorowych kubeczków (16 kolorów do wyboru),')}</li>
                <li>{t('kolorowych kubeczków (10 kolorów do wyboru),')}</li>
                <li>{t('cyfr arabskich (od 0 do 9),')}</li>
                <li>{t('oraz wielu różnorodnych symboli')}</li>
            </List>
            <Paragraph>{t('pomocDrugiParagraf')}</Paragraph>
            <Line />
            <Header>{t('Mata do kodowania - kolorowe kółka')}</Header>
            <Paragraph>{t('pomocTrzeciParagraf')}</Paragraph>
        </StyledHelp>
    );
};

export default Help;
