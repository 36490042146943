export const compareCoordinates = (a, b) => {
    if (a[0] > b[0]) {
        return 1;
    }

    if (a[0] < b[0]) {
        return -1;
    }

    return +a.slice(1) > +b.slice(1);
};
