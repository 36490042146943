import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StyledBoard, Wrapper } from './styled';
import { actions, selectors } from './matSlice';
import { actions as commandsActions } from './Commands/commandsSlice';
import Field from '../Field';
import Commands from './Commands';
import ClearButton from '../../../Common/ClearButton';
import Labels from './Labels';
import { useFetchBoardFromUrl } from '../useFetchBoardFromUrl';
import { LABEL_LEFT_NAME, LABEL_TOP_NAME } from './Labels/styled';

const Mat = () => {
    const board = useSelector(selectors.selectBoard);
    useFetchBoardFromUrl();
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Labels position={LABEL_TOP_NAME} />
            <Labels position={LABEL_LEFT_NAME} />
            <StyledBoard id="mat">
                {board.map((row, rowIndex) => row.map((diskId, columnIndex) => (
                    <Field
                        key={`${rowIndex}-${columnIndex}`}
                        setBoardField={actions.setBoardField}
                        resetBoardField={actions.resetBoardField}
                        rowIndex={rowIndex}
                        columnIndex={columnIndex}
                        diskId={diskId}
                    />
                )))}
            </StyledBoard>
            <ClearButton
                clearAction={actions.resetBoardState}
                content={t('Wyczyść matę')}
                modalContent={t('Czy na pewno chcesz wyczyścić matę?')}
                gridarea="clear"
                showTranslationButton
            />
            <Commands />
            <ClearButton
                clearAction={commandsActions.resetBoardState}
                content={t('Wyczyść listę komend')}
                modalContent={t('Czy na pewno chcesz wyczyścić listę komend?')}
                gridarea="clearCommands"
            />
        </Wrapper>
    );
};

export default Mat;
