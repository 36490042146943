import styled from 'styled-components';

const Wrapper = styled.div`
    position: absolute;
    right: 0;
    height: 100%;
    z-index: 4;
    transition: ${({ isopen }) => (isopen ? 'transform 0.7s' : 'transform 0.7s, width 0.7s')}; 
    width: ${({ isopen }) => (isopen ? '650px' : '0')}; 
    background-color: ${({ theme }) => theme.colors.blue};
    transform: ${({ isopen }) => (isopen ? 'translateX(0)' : 'translateX(650px)')}; 
`;

export default Wrapper;
