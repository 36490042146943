import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../Common/Modal';

const SmallScreenModal = () => {
    const [active, setActive] = useState(window.innerWidth <= 800);
    const { t } = useTranslation();

    return (
        <Modal
            show={active}
            onHide={() => setActive(false)}
            content={t('modalMalaSzerokosc')}
        />
    );
};

export default SmallScreenModal;
