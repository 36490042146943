import styled, { css } from 'styled-components';

export const TranlastionsContainer = styled.div`
    position: absolute;
    padding-left: 10px;
    left: 100%;
    top: 0;
    z-index: 3;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: -10px;
        top: 20px;
        border: 10px solid transparent;
        border-right-color: black;
    }
`;

export const StyledField = styled.div`
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.gray};
    border-width: 1px 0 0 1px;
    position: relative;

    &:not(:hover) ${TranlastionsContainer} {
        display: none;
    }

    ${({ withoutBorder }) => withoutBorder && css`
        border: none;
        padding: 1px;
    `};
`;
