import styled from 'styled-components';

export const StyledHelp = styled.section`
    padding: 50px 50px 0;
    color: ${({ theme }) => theme.colors.white};
    height: 85%;
    overflow: auto;
    display: ${({ isactive }) => (isactive ? 'block' : 'none')};
`;

export const Header = styled.h5`
    font-weight: 700;
    margin-bottom: 16px;
`;

export const Paragraph = styled.p`
    font-size: 14px;
`;

export const List = styled.ul`
    font-size: 14px;
`;
