import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectHash } from '../../RightMenu/rightMenuSlice';

export const useHashUrl = () => {
    const hash = useSelector(selectHash);
    const { i18n } = useTranslation();

    return [useSelector(selectHash), `${(new URL(window.location)).origin}/${i18n.language}/${hash}`];
};
