import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectShowTranslation } from '../../../Common/translationSlice';

const useDiskTranslation = () => {
    const [isShown, setIsShown] = useState(false);
    const showTranslation = useSelector(selectShowTranslation);
    let translationTimeout;

    const onMouseEnterHandler = () => {
        translationTimeout = setTimeout(() => {
            setIsShown(showTranslation);
        }, 500);
    };

    const onMouseEnterLeaveHandler = () => {
        clearTimeout(translationTimeout);
        setIsShown(false);
    };

    return {
        showTranslation: isShown && showTranslation,
        onMouseEnterHandler,
        onMouseEnterLeaveHandler,
    };
};

export default useDiskTranslation;
