import styled, { css } from 'styled-components';
import { Button as BootstrapButton } from 'react-bootstrap';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    transition: fill 0.3s;
    position: relative;

    ${({ gridarea }) => gridarea && css`
        grid-area: ${gridarea};
    `};

    fill: ${({ theme }) => theme.colors.darkBlue};

    &:hover {
        fill: ${({ theme }) => theme.colors.lightGreen};
    }
`;

export const Button = styled.button`
    height: 50%;
    min-height: 30px;
    padding: 4px 32px;
    border: 2px solid ${({ theme }) => theme.colors.darkBlue};
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.darkBlue};
    cursor: pointer;
    transition: color 0.3s, background-color 0.3s;

    &:hover {
        color: ${({ theme }) => theme.colors.darkBlue};
        background-color: ${({ theme }) => theme.colors.white};
    }
`;

export const StyledButton = styled(BootstrapButton)`
    width: 100%;
    border: 2px solid ${({ theme }) => theme.colors.navyBlue};
    border-radius: 0;
    outline: none;
    box-shadow: none;
    font-size: 1.3em;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.navyBlue};
    transition: color 0.3s, background-color 0.3s;

    &:hover {
        border-color: ${({ theme }) => theme.colors.navyBlue};
        color: ${({ theme }) => theme.colors.navyBlue};
        background-color: white;
    }
`;

export const GreenButton = styled(StyledButton)`
    background-color: ${({ theme }) => theme.colors.lightGreen};
    border-color: ${({ theme }) => theme.colors.lightGreen};

    &:hover {
        color: ${({ theme }) => theme.colors.lightGreen};
        border-color: ${({ theme }) => theme.colors.lightGreen};
    }

    &:active {
        color: ${({ theme }) => theme.colors.lightGreen} !important;
        border-color: ${({ theme }) => theme.colors.lightGreen} !important;
        background-color: white !important;
        outline-color: ${({ theme }) => theme.colors.lightGreen} !important;
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem rgb(57 181 74 / 50%) !important;
    }
`;

export const ButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    padding: 10px 30px;

    & button {
        font-weight: 700;
    }
`;

export const ModalInfo = styled.p`
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    line-height: 1;
    color: ${({ theme }) => theme.colors.navyBlue};
`;

export const TogglerButton = styled.button`
    background: none;
    position: absolute;
    right: 0;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.darkBlue};
    padding: 0;

    @media (max-width: 1180px) {
        position: unset;
    }
`;

export const Toggler = styled.div`
    border: 2px solid ${({ theme, showTranslation }) => (showTranslation ? theme.colors.darkBlue : '#999999')};
    background-color: ${({ theme, showTranslation }) => (showTranslation ? theme.colors.darkBlue : '#999999')};
    width: 46px;
    height: 24px;
    position: relative;
    margin-left: 8px;
    transition: all 0.3s ease 0s;

    &::before {
        position: absolute;
        background-color: ${({ theme, showTranslation }) => (showTranslation ? theme.colors.white : '#cccccc')};
        content: '';
        width: 18px;
        height: 18px;
        top: 1px;
        color: black;
        transition: all 0.3s ease 0s;
        left: 1px;
        transform: translateX(${({ showTranslation }) => (showTranslation ? '22px' : '0px')});
    }
`;
