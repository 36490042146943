import styled from 'styled-components';

export const LogoContainer = styled.div`
    text-align: center;
    width: 80px;

    & svg {
        fill: currentColor;
        width: 45px;
        height: 45px;
    }
`;

export const LogoDescription = styled.p`
    padding: 0;
    margin: 0;
    color: inherit;
    font-size: 9px;
    font-weight: 700;
    margin-top: 5px;
    margin-left: ${({ extraMargin }) => (extraMargin ? '8px' : '0')};
`;
