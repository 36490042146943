import { ExportButton } from '../ExportButton';
import useExport from './useExport';
import useExportButtonContent from './useExportButtonContent';

const PdfExportButton = ({ emptyBoardExport }) => {
    const onExportClick = useExport(emptyBoardExport);
    const content = useExportButtonContent(emptyBoardExport);

    return (
        <ExportButton type="button" onClick={onExportClick}>
            {content}
        </ExportButton>
    );
};

export default PdfExportButton;
