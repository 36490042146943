import { takeLatest, call, put } from 'redux-saga/effects';
import { getTasks } from '../api';

import {
    fetchTasks,
    fetchTasksError,
    fetchTasksSuccess,
} from './tasksSlice';

function* fetchTasksHandler() {
    try {
        const tasks = yield call(getTasks);
        const parsedTasks = tasks.map((task) => ({ ...task, board: JSON.parse(task.board) }));
        yield put(fetchTasksSuccess(parsedTasks));
    } catch (error) {
        yield call(alert, 'Nie udało się wczytać zadań, spróbuj odświeżyć stronę.');
        yield put(fetchTasksError());
    }
}

export default function* watchFetchTasks() {
    yield takeLatest(fetchTasks.type, fetchTasksHandler);
}
