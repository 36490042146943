import { all } from 'redux-saga/effects';
import watchMatChanges from './App/boards/Mat/matSaga';
import watchFetchDisks from './Common/disks/disksSaga';
import watchCommandsChanges from './App/boards/Mat/Commands/commandsSaga';
import watchMatCirclesChanges from './App/boards/MatCircles/matCirclesSaga';
import watchMiniMatChanges from './App/boards/MiniMat/miniMatSaga';
import { watchFetchPostMat } from './App/RightColumn/RightMenu/rightMenuSaga';
import watchFetchTasks from './Common/tasks/tasksSaga';

export default function* saga() {
    yield all([
        watchFetchDisks(),
        watchMatChanges(),
        watchCommandsChanges(),
        watchMatCirclesChanges(),
        watchMiniMatChanges(),
        watchFetchPostMat(),
        watchFetchTasks(),
    ]);
}
