import styled, { css } from 'styled-components';

export const LABEL_TOP_NAME = 'top';
export const LABEL_LEFT_NAME = 'left';

export const StyledLabels = styled.div`
    display: grid;

    ${({ position }) => position === LABEL_TOP_NAME && css`
        grid-template-columns: repeat(10, 1fr);
        grid-area: top;
    `}

    ${({ position }) => position === LABEL_LEFT_NAME && css`
        grid-template-rows: repeat(10, 1fr);
        grid-area: left;
    `}
`;

export const Label = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 700;

    @media (max-width: ${({ theme }) => theme.breakpoints.laptopMax}px) {
        font-size: max(12px, 1.5vw);
    };
`;
