import { useTranslation } from 'react-i18next';
import {
    Button, Panel, StyledAccordion, ArrowIcon,
} from './styled';
import { useCollapsed } from './useCollapsed';

const LAST_CATEGORY_NAME = 'krążki kompetencji';

const Accordion = ({ title, content, subCategory = false }) => {
    const [isCollapsed, setIsCollapsed] = useCollapsed(title);
    const { t } = useTranslation();

    return (
        <StyledAccordion>
            <Button
                iscollapsed={isCollapsed ? 1 : 0}
                onClick={() => setIsCollapsed((isCollapsed) => !isCollapsed)}
                subcategory={subCategory}
                borderBottom={title === LAST_CATEGORY_NAME}
            >
                {t(title)}
                <ArrowIcon iscollapsed={isCollapsed ? 1 : 0} />
            </Button>
            <Panel iscollapsed={isCollapsed ? 1 : 0}>{content}</Panel>
        </StyledAccordion>
    );
};

export default Accordion;
