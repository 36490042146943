import { useSelector } from 'react-redux';
import { selectDisksByCategory, selectDisksBySubCategory } from '../../../Common/disks/disksSlice';
import Disk from './Disk';
import StyledDisksContainer from './styled';

const DisksContainer = ({ category, isSubCategory = false }) => {
    const disks = useSelector(isSubCategory
        ? (state) => selectDisksBySubCategory(state, category)
        : (state) => selectDisksByCategory(state, category));

    const direction = {
        0: 'right',
        1: 'bottom',
        2: 'bottom',
        3: 'left',
    };

    const getModalDirection = (positionInRow) => direction[positionInRow];

    return (
        <StyledDisksContainer>
            {disks.map(({ id }, index) => <Disk key={id} id={id} modalDirection={getModalDirection(index % 4)} />)}
        </StyledDisksContainer>
    );
};

export default DisksContainer;
