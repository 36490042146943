import { createSlice } from '@reduxjs/toolkit';
import { MAT_NAME } from '../../boards/Mat/matSlice';

const rightMenuSlice = createSlice({
    name: 'rightMenu',
    initialState: {
        activeTab: '',
        activeBoard: MAT_NAME,
        hash: '',
    },
    reducers: {
        setActiveTab: (state, { payload: activeTab }) => { state.activeTab = activeTab; },
        setActiveBoard: (state, { payload: activeBoard }) => { state.activeBoard = activeBoard; },
        setHash: (state, { payload: hash }) => { state.hash = hash; },
        fetchPostMat: () => { },
    },
});

export const {
    setActiveTab,
    setActiveBoard,
    setHash,
    fetchPostMat,
} = rightMenuSlice.actions;

export const selectState = (state) => state.rightMenu;
export const selectActiveTab = (state) => selectState(state).activeTab;
export const selectActiveBoard = (state) => selectState(state).activeBoard;
export const selectHash = (state) => selectState(state).hash;

export default rightMenuSlice.reducer;
