import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        show: false,
    },
    reducers: {
        setShow: (state, { payload: show }) => {
            state.show = show;
        },
    },
});

export const {
    setShow,
} = modalSlice.actions;

export const selectModalState = (state) => state.modal;
export const selectShow = (state) => selectModalState(state).show;

export default modalSlice.reducer;
