import styled from 'styled-components';
import { ReactComponent as LogoPL } from './wirtualna_mata_logo_pl.svg';
import { ReactComponent as LogoEN } from './wirtualna_mata_logo_en.svg';
import { ReactComponent as LogoCZ } from './wirtualna_mata_logo_cz.svg';
import { ReactComponent as LogoUA } from './wirtualna_mata_logo_ua.svg';

export const DiskSectionContainer = styled.div`
    width: 100%;
    height: 100vh;
    min-height: 550px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    grid-gap: 16px;
`;

const logoStyles = `
    width: 150px;
    height: auto;
    margin: 16px 16px 0;

    @media (max-width: 1200px) {
        width: 120px;
    };

    @media (max-width: 992px) {
        width: 100px;
    };
`;

export const StyledLogoPL = styled(LogoPL)`
    ${logoStyles}
`;

export const StyledLogoEN = styled(LogoEN)`
    ${logoStyles}
`;

export const StyledLogoCZ = styled(LogoCZ)`
    ${logoStyles}
`;

export const StyledLogoUA = styled(LogoUA)`
    ${logoStyles}
`;

export const AllDiskContainer = styled.div`
    height: 100%;
    overflow-y: scroll;
`;

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-right: 16px;
`;
