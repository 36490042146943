export const setRobotoFont = (doc, fontFile) => {
    doc.addFileToVFS('Roboto-Regular.ttf', fontFile);
    doc.addFont('Roboto-Regular.ttf', 'Roboto', 'normal');
    doc.setFont('Roboto');
};

export const drawHeaderImages = (doc, logoUdp, logoKodowanie) => {
    doc.addImage(logoUdp, 'PNG', 15, 13, 20, 20);
    doc.addImage(logoKodowanie, 'PNG', 45, 15, 27, 18);
};

export const drawLink = (doc, link, t) => {
    doc.setFontSize(12);
    doc.textWithLink(t('Otwórz w aplikacji:'), 85, 22, { url: link });
    doc.setTextColor('#007bff');
    doc.setFontSize(14);
    const splittedLink = doc.splitTextToSize(link.toString(), 110);
    doc.textWithLink(splittedLink, 85, 30, { url: link });
    doc.setTextColor('#000000');
};

export const drawFooter = (doc) => {
    doc.setFontSize(10);
    doc.setTextColor('#007bff');
    doc.textWithLink('www.akademia.uczymydzieciprogramowac.pl', 15, 288,
        { url: 'https://akademia.uczymydzieciprogramowac.pl/pl/udp' });
    doc.textWithLink('www.facebook.com/UczymyDzieciProgramowac', 118, 288,
        { url: 'https://www.facebook.com/UczymyDzieciProgramowac' });
};

export const drawCoordinatesPage = (doc, coordinates, t) => {
    doc.addPage();
    doc.setFontSize(12);
    doc.setTextColor('#000000');
    const coordinatesStart = [15, 30];
    doc.text(`${t('Współrzędne na macie')}:`, 15, 20);
    doc.setFontSize(16);
    coordinates.forEach(({ path, positions }, coordinateIndex) => {
        doc.addImage(path, 'PNG', coordinatesStart[0], coordinatesStart[1] + 20 * (coordinateIndex + 0.3), 16, 16);
        const splitedPositions = doc.splitTextToSize(positions.toString(), 150);
        doc.text(coordinatesStart[0] + 20, coordinatesStart[1] + 20 * (coordinateIndex + 0.6), splitedPositions);
    });

    drawFooter(doc);
};
