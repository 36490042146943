import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none; 
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: grid;
    justify-content: center;
    grid-template-columns: calc(1/15 * 100vh) calc(10/15 * 100vh) calc(1/15 * 100vh); 
    grid-template-rows: calc(1/15 * 100vh) calc(10/15 * 100vh) calc(1/15 * 100vh) calc(2/15 * 100vh) calc(1/15 * 100vh);
    grid-template-areas: 
        '  .   top   .  ' 
        'left board  .  '
        ' .   clear  .  '
        ' .  commands . '
        ' .  clearCommands .';

    @media (max-aspect-ratio: 15/9) {
        grid-template-columns: calc(1/15 * 60vw) calc(10/15 * 60vw) calc(1/15 * 60vw);
        grid-template-rows: 
            calc(1/15 * 60vw) calc(10/15 * 60vw) calc(1/15 * 60vw) calc(2/15 * 60vw) calc(1/15 * 60vw);
    }

    @media (max-width: 800px) {
        height: 800px;
        grid-template-columns: calc(1/15 * 800px) calc(10/15 * 800px) calc(1/15 * 800px);
        grid-template-rows: 
            calc(1/15 * 800px) calc(10/15 * 800px) calc(1/15 * 800px) calc(2/15 * 800px) calc(1/15 * 800px);
    }

    @media (max-height: 550px) {
        height: 550px;
        grid-template-columns: calc(1/15 * 550px) calc(10/15 * 550px) calc(1/15 * 550px);
        grid-template-rows: 
            calc(1/15 * 550px) calc(10/15 * 550px) calc(1/15 * 550px) calc(2/15 * 550px) calc(1/15 * 550px);
    }
`;

export const StyledBoard = styled.div`
    background-color: white;
    display: grid;
    grid-template-columns: repeat(10, 10%);
    grid-template-rows: repeat(10, 10%);
    grid-area: board;
    border: 1px solid ${({ theme }) => theme.colors.gray};
    border-width: 0 1px 1px 0;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: calc(50% - 2px);
        left: 1px;
        width: 100%;
        display: flex;
        align-items: center;
        border-top: 4px solid red;
        z-index: 2;
    }

    &:after {
        content: "";
        position: absolute;
        right: calc(50% - 2px);
        top: 1px;
        height: 100%;
        display: flex;
        align-items: center;
        border-right: 4px solid red;
        z-index: 2;
    }
`;
