import styled, { css } from 'styled-components';

export default styled.div`
    padding: 4px;
    margin: 4px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.white};
    position: relative;

    ${({ isactive }) => isactive && css`
        box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.colors.black};
    `}
    
    &:hover {
        box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.colors.gray};
    }

    &:active {
        box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.colors.black};
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.laptopMax}px) {
        padding: 4px;
    };
`;

export const TranlastionsContainer = styled.div`
    position: absolute;
    ${({ modalDirection }) => (modalDirection === 'left' ? css`
        padding-right: 10px;
        right: 100%;
        top: 0;
    ` : modalDirection === 'bottom' ? css`
        padding-top: 10px;
        top: 100%;
        left: -3vw;
    ` : css`
        padding-left: 10px;
        left: 100%;
        top: 0;
    `)}

    z-index: 3;

    @media (max-width: 992px) {
        display: none;
    };
`;

export const Arrow = styled.div`
    display: block;
    position: absolute;
    border: 10px solid transparent;
    z-index: 3;

    ${({ modalDirection }) => (modalDirection === 'left' ? css`
        left: -10px;
        top: 20px;
        border-left-color: black;
    ` : modalDirection === 'bottom' ? css`
        bottom: -10px;
        left: calc(50% - 10px);
        border-bottom-color: black;
    ` : css`
        right: -10px;
        top: 20px;
        border-right-color: black;
    `)}

    @media (max-width: 992px) {
        display: none;
    };
`;
