import { Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Mat from '../boards/Mat';
import MatCircles from '../boards/MatCircles';
import { selectActiveBoard } from '../RightColumn/RightMenu/rightMenuSlice';
import ActiveDiskModal from './ActiveDiskModal';
import Navigation from './Navigation';
import {
    Logo, StyledTabs, TabContent, TabPane,
} from './styled';

const Tabs = () => {
    const activeBoard = useSelector(selectActiveBoard);

    return (
        <StyledTabs>
            <Tab.Container activeKey={activeBoard}>
                <Navigation />
                <TabContent>
                    <TabPane eventKey="mat">
                        <Mat />
                    </TabPane>
                    <TabPane eventKey="matCircles">
                        <MatCircles />
                    </TabPane>
                </TabContent>
                <ActiveDiskModal />
            </Tab.Container>
            <Logo />
        </StyledTabs>
    );
};

export default Tabs;
