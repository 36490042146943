import styled, { css } from 'styled-components';
import { ReactComponent as Icon } from './next.svg';

export const StyledAccordion = styled.section`
    display: block;
`;

export const Button = styled.button`
    background-color: ${({ iscollapsed, theme }) => (iscollapsed ? theme.colors.white : theme.colors.lightGreen)};
    color: ${({ iscollapsed, theme }) => (iscollapsed ? theme.colors.black : theme.colors.white)};
    cursor: pointer;
    padding: 18px;
    width: 100%;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.gray};
    border-width: 1px 1px ${({ borderBottom }) => (borderBottom ? '1px' : 0)} 0;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;

    &:hover {
        background-color: ${({ theme }) => theme.colors.lightGreen};
        color: ${({ theme }) => theme.colors.white};
    }

    ${({ subcategory }) => subcategory && css`
        padding-left: 36px;
        font-weight: 400;
        text-transform: unset;
        background-color: ${({ iscollapsed, theme }) => (iscollapsed ? theme.colors.white : theme.colors.mantis)};

        &:hover {
            background-color: ${({ theme }) => theme.colors.mantis};
        }
    `};
`;

export const Panel = styled.div`
    max-height: ${({ iscollapsed }) => (iscollapsed ? '0' : '8000px')};
    transition: ${({ iscollapsed }) => (
        iscollapsed ? 'max-height 0.5s cubic-bezier(0, 1, 0, 1)' : 'max-height 1s ease-in-out'
    )};
    overflow: ${({ iscollapsed }) => (iscollapsed ? 'hidden' : 'visible')};
`;

export const ArrowIcon = styled(Icon)`
    fill: ${({ iscollapsed, theme }) => (iscollapsed ? theme.colors.black : theme.colors.white)};
    width: 15px;
    height: 15px;
    transition: all 0.3s;
    transform: ${({ iscollapsed }) => (iscollapsed ? 'rotateZ(90deg)' : 'rotateZ(-90deg)')};
`;
