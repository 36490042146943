import { useRef, useState } from 'react';

export const usePlayer = () => {
    const [isAudioOn, setIsAudioOn] = useState(false);
    const playerRef = useRef();

    const onPlayerButtonClick = () => {
        setIsAudioOn((isAudioOn) => !isAudioOn);
        if (isAudioOn) {
            playerRef.current.audioEl.current.pause();

            return;
        }

        playerRef.current.audioEl.current.play();
    };

    return {
        isAudioOn,
        setIsAudioOn,
        playerRef,
        onPlayerButtonClick,
    };
};
