import { createSlice } from '@reduxjs/toolkit';
import { getFromLocalStorage } from './localStorage';

const createBoardSlice = ({ name, defaultInitialState }) => {
    const slice = createSlice({
        name,
        initialState: {
            board: getFromLocalStorage(name) || defaultInitialState,
            loading: false,
        },
        reducers: {
            setBoardState: (state, { payload: board }) => {
                state.board = board;
            },
            setBoardField: ({ board }, { payload: field }) => {
                if (field.row >= 0 && field.row < defaultInitialState.length
                    && field.column >= 0 && field.column < defaultInitialState[0].length) {
                    board[field.row][field.column] = field.diskId;
                }
            },
            resetBoardField: ({ board }, { payload: field }) => {
                if (field.row >= 0 && field.row < defaultInitialState.length
                    && field.column >= 0 && field.column < defaultInitialState[0].length) {
                    board[field.row][field.column] = defaultInitialState[field.row][field.column];
                }
            },
            resetBoardState: (state) => {
                state.board = defaultInitialState;
            },
            fetchBoard: (state) => { state.loading = true; },
            setLoading: (state, { payload: loading }) => {
                state.loading = loading;
            },
        },
    });
    const selectBoardsState = (state) => state[name];

    return {
        reducer: slice.reducer,
        actions: slice.actions,
        selectors: {
            selectBoard: (state) => selectBoardsState(state).board,
            selectLoading: (state) => selectBoardsState(state).loading,
        },
    };
};

export default createBoardSlice;
