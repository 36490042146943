import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { actions } from './Mat/matSlice';

export const useFetchBoardFromUrl = () => {
    const dispatch = useDispatch();
    const getHashFromUrl = (url) => url?.pathname?.split('/').slice(-1)[0];
    const location = useLocation();

    useEffect(() => {
        const hash = getHashFromUrl(location);
        if (hash) {
            dispatch(actions.fetchBoard(hash));
        }

        // eslint-disable-next-line
    }, []);
};
