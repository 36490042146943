import { useDispatch, useSelector } from 'react-redux';
import DiskImage from '../../../../Common/DiskImage';
import { selectActiveDiskId, setActiveDiskId, setDragDiskId } from '../../../../Common/disks/disksSlice';
import Translations from '../../../boards/Field/Translations';
import useDiskTranslation from '../../../boards/Field/useDiskTranslation';
import StyledDisk, { TranlastionsContainer, Arrow } from './styled';

const Disk = ({ id, modalDirection }) => {
    const dispatch = useDispatch();
    const activeDiskId = useSelector(selectActiveDiskId);
    const { showTranslation, onMouseEnterHandler, onMouseEnterLeaveHandler } = useDiskTranslation();

    return (
        <StyledDisk
            onClick={() => dispatch(setActiveDiskId(id))}
            isactive={id === activeDiskId}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseEnterLeaveHandler}
            onDragStart={() => {
                dispatch(setActiveDiskId(id));
                dispatch(setDragDiskId(id));
            }}
        >
            <DiskImage id={id} />
            {showTranslation && id !== 0 && (
                <>
                    <Arrow modalDirection={modalDirection} />
                    <TranlastionsContainer modalDirection={modalDirection}>
                        <Translations diskId={id} />
                    </TranlastionsContainer>
                </>
            )}
        </StyledDisk>
    );
};

export default Disk;
