import DiskSection from './DiskSection';
import RightColumn from './RightColumn';
import SmallScreenModal from './SmallScreenModal';
import Tabs from './Tabs';
import { Container } from './styled';
import useLanguageFromUrl from './useLanguageFromUrl';

const App = () => {
    useLanguageFromUrl();

    return (
        <>
            <Container>
                <DiskSection />
                <Tabs />
                <RightColumn />
            </Container>
            <SmallScreenModal />
        </>
    );
};

export default App;
