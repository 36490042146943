import { Nav } from 'react-bootstrap';
import styled from 'styled-components';

export const Wrapper = styled.div`
    position: absolute;
    right: -99px;
    margin-top: 50px;
    z-index: 3;

    @media (max-width: ${({ theme }) => theme.breakpoints.laptopMax}px) {
        right: -93px;
    };

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletVertivalMax}px) {
        right: -57px;
    };
`;

export const StyledNav = styled(Nav)`
    display: grid;
    grid-template-columns: auto;
`;

export const NavItem = styled(Nav.Item)`
    font-family: 'Baloo Tammudu 2', cursive;
    font-size: 22px;
    writing-mode: vertical-rl;
    cursor: pointer;
    width: 50px;
    justify-content: center;
    font-weight: 800;

    & .active {
        color: ${({ theme }) => theme.colors.darkBlue} !important;
        background-color: ${({ theme }) => theme.colors.white} !important;
        box-shadow: none !important;
    }
`;

export const NavLink = styled(Nav.Link)`
    padding: 10vh 38px 10vh 28px;
    background-color: ${({ theme }) => theme.colors.lightGreen};
    color: ${({ theme }) => theme.colors.darkGreen};
    box-shadow: inset 0px 0px 3px 0px ${({ theme }) => theme.colors.black};

    &:hover {
        color: ${({ theme }) => theme.colors.white};
    }

    &:focus {
        outline: unset;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.laptopMax}px) {
        font-size: 18px;
    };

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletVertivalMax}px) {
        padding: 10vh 19px 10vh 14px;
        font-size: 16px;
    };
`;
