import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MAT_NAME, selectors } from '../../../../boards/Mat/matSlice';
import { selectors as commandSelectors } from '../../../../boards/Mat/Commands/commandsSlice';
import { MAT_CIRCLES_NAME, selectors as matCirclesSelectors } from '../../../../boards/MatCircles/matCirclesSlice';
import { selectActiveBoard } from '../../../RightMenu/rightMenuSlice';
import { useHashUrl } from '../useHashUrl';
import { selectDisks } from '../../../../../Common/disks/disksSlice';
import useCoordinates from '../../../../DiskSection/DisksContainer/CoordinatesContainer/useCoordinates';
import { getDefaultImages } from '../../../../../Common/getDefaultImages';
import createMatCirclesDocument from './pdf/createMatCirclesDocument';
import createMatDocument from './pdf/createMatDocument';

const useExport = (emptyBoardExport) => {
    const activeBoard = useSelector(selectActiveBoard);
    const [hash, link] = useHashUrl();
    const coordinates = useCoordinates({ allTypesOfDisk: true });
    const board = useSelector(selectors.selectBoard);
    const circlesBoard = useSelector(matCirclesSelectors.selectBoard);
    const commands = useSelector(commandSelectors.selectBoard);
    const disks = useSelector(selectDisks);
    const { t, i18n } = useTranslation();

    const parseDiskIdToPath = (id, disks, defaultImage = '') => {
        const disk = disks.find((disk) => disk.id === id);
        if (disk) {
            return `${process.env.REACT_APP_CND_URL}/${disk?.path.slice(0, -3)}png`;
        }

        return defaultImage;
    };

    const parsedBoard = board.map((row) => row.map((diskId) => parseDiskIdToPath(diskId, disks)));
    const parsedCirclesBoard = circlesBoard.map((row, rowIndex) => row.map((diskId, columnIndex) => (
        parseDiskIdToPath(diskId, disks, getDefaultImages('png')[rowIndex][columnIndex])
    )));
    const parsedCommands = commands.map((row) => row.map((diskId) => parseDiskIdToPath(diskId, disks)));
    const parsedCoordinates = coordinates.map((coordinate) => (
        {
            ...coordinate,
            path: parseDiskIdToPath(coordinate.id, disks),
        }
    ));

    const documentData = {
        t,
        language: i18n.language,
        hash,
        link,
        board: activeBoard === MAT_NAME ? parsedBoard : parsedCirclesBoard,
        commands: parsedCommands,
        coordinates: parsedCoordinates,
    };

    const defaultBoard = [...new Array(10).fill([...new Array(10).fill('')])];
    const emptyDocumentData = {
        t,
        language: i18n.language,
        hash,
        link,
        board: activeBoard === MAT_NAME ? defaultBoard : getDefaultImages('png'),
        commands: [...new Array(2).fill([...new Array(10).fill('')])],
        coordinates: parsedCoordinates,
    };

    if (activeBoard === MAT_NAME) {
        return () => createMatDocument(emptyBoardExport ? emptyDocumentData : documentData);
    }

    if (activeBoard === MAT_CIRCLES_NAME) {
        return () => createMatCirclesDocument(emptyBoardExport ? emptyDocumentData : documentData);
    }

    return () => { };
};

export default useExport;
