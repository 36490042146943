import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postEmail } from '../../../../../Common/api';
import { useHashUrl } from '../useHashUrl';

const useEmail = () => {
    const { t } = useTranslation();
    const [, url] = useHashUrl();
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const isEmailValid = (email) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);

    const onEmailInputChange = (e) => {
        setEmail(e.target.value);
        if (isEmailValid(e.target.value)) {
            setErrorMessage('');
        }
    };

    const onClick = () => {
        if (!isEmailValid(email)) {
            setErrorMessage(t('Podaj prawidłowy email!'));
            return false;
        }
        postEmail({
            email,
            link: url,
        });

        setEmail('');
        setErrorMessage('');

        return true;
    };

    return {
        onEmailInputChange,
        onClick,
        errorMessage,
        email,
    };
};

export default useEmail;
