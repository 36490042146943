import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Field from '../Field';
import { selectors, actions } from './matCirclesSlice';
import { StyledMatCircles, Wrapper } from './styled';
import { getDefaultImages } from '../../../Common/getDefaultImages';
import ClearButton from '../../../Common/ClearButton';

const MatCircles = () => {
    const matCircles = useSelector(selectors.selectBoard);
    const { t } = useTranslation();

    return (
        <Wrapper>
            <StyledMatCircles id="matCircles">
                {matCircles.map((row, rowIndex) => row.map((diskId, columnIndex) => (
                    <Field
                        key={`${rowIndex}-${columnIndex}`}
                        setBoardField={actions.setBoardField}
                        resetBoardField={actions.resetBoardField}
                        rowIndex={rowIndex}
                        columnIndex={columnIndex}
                        diskId={diskId}
                        withoutBorder
                        defaultImage={getDefaultImages('svg')[rowIndex][columnIndex]}
                    />
                )))}
            </StyledMatCircles>
            <ClearButton
                clearAction={actions.resetBoardState}
                content={t('Wyczyść matę')}
                modalContent={t('Czy na pewno chcesz wyczyścić matę?')}
                showTranslationButton
            />
        </Wrapper>
    );
};

export default MatCircles;
