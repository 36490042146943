import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import saga from './saga';
import disksReducer from './Common/disks/disksSlice';
import tasksReducer from './Common/tasks/tasksSlice';
import matReducer from './App/boards/Mat/matSlice';
import commandsReducer from './App/boards/Mat/Commands/commandsSlice';
import matCirclesReducer from './App/boards/MatCircles/matCirclesSlice';
import miniMatReducer from './App/boards/MiniMat/miniMatSlice';
import modalReducer from './App/Tabs/ActiveDiskModal/modalSlice';
import rightMenuReducer from './App/RightColumn/RightMenu/rightMenuSlice';
import translationReducer from './Common/translationSlice';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: {
        disks: disksReducer,
        mat: matReducer,
        commands: commandsReducer,
        matCircles: matCirclesReducer,
        miniMat: miniMatReducer,
        modal: modalReducer,
        rightMenu: rightMenuReducer,
        tasks: tasksReducer,
        translation: translationReducer,
    },
    middleware: [sagaMiddleware],
});

sagaMiddleware.run(saga);

export default store;
