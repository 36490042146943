import {
    call, takeEvery, select, put,
} from 'redux-saga/effects';
import { getBoard } from '../../Common/api';
import { setActiveBoard } from '../RightColumn/RightMenu/rightMenuSlice';
import { saveInLocalStorage } from './localStorage';
import { actions as commandsActions } from './Mat/Commands/commandsSlice';
import { MAT_NAME } from './Mat/matSlice';
import { actions as matCirclesActions, MAT_CIRCLES_NAME } from './MatCircles/matCirclesSlice';

function* createBoardsSaga({ selectors, actions, boardName }) {
    function* saveBoardInLocalStorageHandler() {
        const board = yield select(selectors.selectBoard);
        yield call(saveInLocalStorage, board, boardName);
    }

    function* fetchBoardHandler({ payload: hash }) {
        const board = yield call(getBoard, hash);

        if (board.type === MAT_NAME) {
            yield put(setActiveBoard(board.type));
            yield put(actions.setBoardState(JSON.parse(board.data)));
            yield put(commandsActions.setBoardState(JSON.parse(board.commands)));
        }
        if (board.type === MAT_CIRCLES_NAME) {
            yield put(setActiveBoard(board.type));
            yield put(matCirclesActions.setBoardState(JSON.parse(board.data)));
        }
    }

    yield takeEvery('*', saveBoardInLocalStorageHandler);
    yield takeEvery(actions.fetchBoard.type, fetchBoardHandler);
}

export default createBoardsSaga;
