import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { resetActiveTask, selectActiveTask } from '../../../../Common/tasks/tasksSlice';
import CongratsModal from './CongratsModal';
import useCoordinates from './useCoordinates';
import ClearButton from '../../../../Common/ClearButton';
import Coordinates, { BOARD_COORDINATES_TITLE, TASK_COORDINATES_TITLE } from './Coordinates';
import { useCongratsModal } from './useCongratsModal';

const CoordinatesContainer = () => {
    const coordinates = useCoordinates();
    const activeTask = useSelector(selectActiveTask);
    const taskCoordinates = useCoordinates({ taskBoard: activeTask?.board });
    const [show, setShow] = useCongratsModal();
    const { t } = useTranslation();

    return (
        <>
            <Coordinates
                coordinates={activeTask ? taskCoordinates : coordinates}
                title={activeTask ? TASK_COORDINATES_TITLE : BOARD_COORDINATES_TITLE}
                extraContent={activeTask ? (
                    <ClearButton
                        clearAction={resetActiveTask}
                        content={t('Zamknij zadanie')}
                        modalContent={t('Czy na pewno chcesz zamknąć zadanie?')}
                    />
                ) : undefined}
            />
            <CongratsModal show={show} setShow={setShow} />
        </>
    );
};

export default CoordinatesContainer;
