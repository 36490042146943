import { useRef } from 'react';
import CollapsingSection from './CollapsingSection';
import Footer from './Footer';
import RightMenu from './RightMenu';
import StyledRightColumn from './styled';
import useOutsideHideMenu from './useOutsideHideMenu';

const RightColumn = () => {
    const ref = useRef();
    useOutsideHideMenu(ref);

    return (
        <StyledRightColumn ref={ref}>
            <RightMenu />
            <Footer />
            <CollapsingSection />
        </StyledRightColumn>
    );
};

export default RightColumn;
