import { useTranslation } from 'react-i18next';
import { useHashUrl } from '../useHashUrl';
import { Container, StyledInput, Button } from '../Input/styled';
import { useInputActions } from './useInputActions';

const HashInput = () => {
    const [, url] = useHashUrl();
    const [inputRef, onInputFocus, copyTextToClipboard] = useInputActions(url);
    const { t } = useTranslation();

    return (
        <Container>
            <StyledInput ref={inputRef} value={url} onFocus={onInputFocus} type="text" readOnly />
            <Button onClick={() => copyTextToClipboard(url)}>{t('Kopiuj')}</Button>
        </Container>
    );
};

export default HashInput;
