import createBoardSlice from '../../createBoardSlice';

export const commandsSlice = createBoardSlice({
    name: 'commands',
    defaultInitialState: [...new Array(2).fill([...new Array(10).fill(0)])],
});

export const { actions, selectors } = commandsSlice;

export default commandsSlice.reducer;
