import { useTranslation } from 'react-i18next';
import { Container, StyledInput, Button } from '../Input/styled';
import { ErrorMessage } from './styled';
import useEmail from './useEmail';

const EmailInput = () => {
    const { t } = useTranslation();

    const {
        onEmailInputChange, onClick, errorMessage, email,
    } = useEmail();

    return (
        <Container>
            <StyledInput
                type="email"
                placeholder={t('Podaj e-mail, na który chcesz otrzymać link do projektu')}
                value={email}
                onChange={onEmailInputChange}
            />
            <Button onClick={onClick}>{t('Wyślij')}</Button>
            <ErrorMessage>{errorMessage}</ErrorMessage>
        </Container>
    );
};

export default EmailInput;
