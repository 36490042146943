import { useSelector } from 'react-redux';
import { selectActiveDisk } from '../../../Common/disks/disksSlice';
import { StyledField, TranlastionsContainer } from './styled';
import DiskImage from '../../../Common/DiskImage';
import Translations from './Translations';
import useDiskTranslation from './useDiskTranslation';
import useDragAndDrop from './useDragAndDrop';

const Field = ({
    setBoardField,
    resetBoardField,
    rowIndex,
    columnIndex,
    diskId,
    withoutBorder = false,
    defaultImage = null,
}) => {
    const activeDisk = useSelector(selectActiveDisk);

    const { showTranslation, onMouseEnterHandler, onMouseEnterLeaveHandler } = useDiskTranslation();
    const {
        onFieldClick, onDragStart, onDragEnd, onDragOver, onDrop,
        onTouchStart, onFieldTouch, onTouchEnd,
    } = useDragAndDrop(setBoardField, resetBoardField, rowIndex, columnIndex, diskId, activeDisk);

    return (
        <StyledField
            key={`${rowIndex}-${columnIndex}`}
            onClick={() => onFieldClick(rowIndex, columnIndex)}
            activeDisk={diskId === 0 ? activeDisk : null}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseEnterLeaveHandler}
            withoutBorder={withoutBorder}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
            onDrop={onDrop}
            onTouchStart={onTouchStart}
            onTouchMove={onFieldTouch}
            onTouchEndCapture={onTouchEnd}
        >
            <DiskImage id={diskId} defaultImage={defaultImage} />
            {showTranslation && diskId !== 0 && (
                <TranlastionsContainer>
                    <Translations diskId={diskId} />
                </TranlastionsContainer>
            )}
        </StyledField>
    );
};

export default Field;
