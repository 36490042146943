import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MAT_NAME } from '../../boards/Mat/matSlice';
import { MAT_CIRCLES_NAME } from '../../boards/MatCircles/matCirclesSlice';
import { setActiveBoard } from '../../RightColumn/RightMenu/rightMenuSlice';
import {
    StyledNav, NavItem, NavLink, Wrapper,
} from './styled';

const Navigation = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <Wrapper>
            <StyledNav>
                <NavItem onClick={() => dispatch(setActiveBoard(MAT_NAME))}>
                    <NavLink eventKey="mat">{t('mata 10 x 10')}</NavLink>
                </NavItem>
                <NavItem onClick={() => dispatch(setActiveBoard(MAT_CIRCLES_NAME))}>
                    <NavLink eventKey="matCircles">{t('kółka 9 x 9')}</NavLink>
                </NavItem>
            </StyledNav>
        </Wrapper>
    );
};

export default Navigation;
