import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RightMenuItem, StyledRightMenu } from './styled';
import { ReactComponent as HelpIcon } from './pomoc.svg';
import { ReactComponent as ExportIcon } from './export.svg';
import { ReactComponent as TasksIcon } from './zadania.svg';
import Icon from '../Icon';
import {
    fetchPostMat, selectActiveBoard, selectActiveTab, setActiveTab,
} from './rightMenuSlice';
import { MAT_NAME, selectors as matSelectors } from '../../boards/Mat/matSlice';
import { selectors as commandSelectors } from '../../boards/Mat/Commands/commandsSlice';
import { MAT_CIRCLES_NAME, selectors as matCirclesSelectors } from '../../boards/MatCircles/matCirclesSlice';
import { selectors as miniMatSelectors } from '../../boards/MiniMat/miniMatSlice';

export const TASKS_TAB_NAME = 'tasks';
export const EXPORT_TAB_NAME = 'export';
export const HELP_TAB_NAME = 'help';

const RightMenu = () => {
    const activeTab = useSelector(selectActiveTab);
    const dispatch = useDispatch();
    const activeBoard = useSelector(selectActiveBoard);

    const selectBoard = () => (
        activeBoard === MAT_NAME
            ? matSelectors.selectBoard
            : activeBoard === MAT_CIRCLES_NAME
                ? matCirclesSelectors.selectBoard
                : miniMatSelectors.selectBoard
    );

    const board = useSelector(selectBoard());
    const commands = useSelector(commandSelectors.selectBoard);

    const onExportClick = () => {
        if (activeTab === EXPORT_TAB_NAME) {
            dispatch(setActiveTab(''));

            return;
        }

        dispatch(setActiveTab(EXPORT_TAB_NAME));
        dispatch(fetchPostMat({ activeBoard, board, commands }));
    };

    const { t } = useTranslation();

    return (
        <StyledRightMenu>
            <RightMenuItem
                isactive={activeTab === TASKS_TAB_NAME}
                onClick={() => dispatch(setActiveTab(activeTab === TASKS_TAB_NAME ? '' : TASKS_TAB_NAME))}
            >
                <Icon Image={TasksIcon} description={t('ZADANIA')} />
            </RightMenuItem>
            <RightMenuItem
                isactive={activeTab === EXPORT_TAB_NAME}
                onClick={onExportClick}
            >
                <Icon Image={ExportIcon} description={t('EKSPORT')} />
            </RightMenuItem>
            <RightMenuItem
                isactive={activeTab === HELP_TAB_NAME}
                onClick={() => dispatch(setActiveTab(activeTab === HELP_TAB_NAME ? '' : HELP_TAB_NAME))}
            >
                <Icon Image={HelpIcon} description={t('POMOC')} />
            </RightMenuItem>
        </StyledRightMenu>
    );
};

export default RightMenu;
