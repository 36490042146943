import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveTask, selectWasSolutionLoaded } from '../../../../Common/tasks/tasksSlice';
import { selectors } from '../../../boards/Mat/matSlice';

export const useCongratsModal = () => {
    const [show, setShow] = useState(false);
    const taskBoard = useSelector(selectActiveTask)?.board;
    const wasSolutionLoaded = useSelector(selectWasSolutionLoaded);
    const board = useSelector(selectors.selectBoard);

    useEffect(() => {
        if (taskBoard && !wasSolutionLoaded && (JSON.stringify(board) === JSON.stringify(taskBoard))) {
            setShow(true);
        }
        // eslint-disable-next-line
    }, [board]);

    return [show, setShow];
};
