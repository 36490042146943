import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none; 
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: calc(1/15 * 100vh);
    display: grid;
    grid-template-columns: calc(2/3 * 100vh);
    grid-template-rows: calc(2/3 * 100vh) calc(1/15 * 100vh);
    justify-content: center;

    @media (max-aspect-ratio: 15/9) {
        grid-template-columns: 40vw;
        grid-template-rows: 40vw  5vw;
        padding: calc(1/15 * 60vh);
    }

    @media (max-width: 800px) {
        height: 800px;
        grid-template-columns: 640px;
        grid-template-rows: 640px 80px;
    }

    @media (max-height: 550px) {
        height: 550px;
        padding: 0;
        grid-template-columns: 500px;
        grid-template-rows: 500px 50px;
    }
`;

export const StyledMatCircles = styled.div`
    background: white;
    display: grid;
    grid-template-columns: repeat(9, calc(1/9 * 100%));
    grid-template-rows: repeat(9, calc(1/9 * 100%));
    outline: 1px solid black;
`;
