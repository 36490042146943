import createBoardsSaga from '../createBoardSaga';
import { selectors, actions, MAT_NAME } from './matSlice';

function* watchMatChanges() {
    yield createBoardsSaga({
        selectors,
        actions,
        boardName: MAT_NAME,
    });
}

export default watchMatChanges;
