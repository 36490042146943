import { changeLanguage } from 'i18next';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const availableLanguages = ['pl', 'en', 'cz', 'ua'];

const useLanguageFromUrl = () => {
    const location = useLocation();
    const languageFromUrl = location?.pathname?.split('/')?.[1];

    useEffect(() => {
        changeLanguage(availableLanguages.includes(languageFromUrl) ? languageFromUrl : 'pl');
    }, [languageFromUrl]);
};

export default useLanguageFromUrl;
