import { Tab } from 'react-bootstrap';
import styled from 'styled-components';
import { ReactComponent as LogoKodowanie } from './kodowanie_na_dywanie_logo.svg';

export const TabContent = styled(Tab.Content)`
    width: 100%;
    height: 100%;
`;

export const TabPane = styled(Tab.Pane)`
    width: 100%;
    height: 100%;
    padding: 0 32px;
    display: flex;
    justify-content: center;
`;

export const StyledTabs = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 550px;
    background-color: ${({ theme }) => theme.colors.white};
`;

export const Logo = styled(LogoKodowanie)`
    position: absolute;
    width: 100px;
    right: 30px;
    top: 20px;

    @media (max-width: 1800px) {
        right: 10px;
        top: 10px;
        width: 70px;
    };

    @media (max-width: 1200px) {
        right: 10px;
        top: 10px;
        width: 5vw;
        min-width: 50px;
    };
`;
