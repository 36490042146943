import { useSelector } from 'react-redux';
import Image from './styled';
import defaultBackground from './default.svg';
import { selectDiskById, selectLoading } from '../disks/disksSlice';

const DiskImage = ({ id, defaultImage }) => {
    const disk = useSelector((state) => selectDiskById(state, id));
    const disksLoading = useSelector(selectLoading);
    const imagePath = disk ? `${process.env.REACT_APP_CND_URL}/${disk?.path}` : defaultImage || defaultBackground;

    return !disksLoading && (
        <Image src={imagePath} alt={id} className="disk-image" />
    );
};

export default DiskImage;
