import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setLanguage } from '../../../Common/disks/disksSlice';
import {
    Icon, Input, RemoveIcon, Wrapper,
} from './styled';
import { useOnInputSearchChange } from './useOnInputSearchChange';

const Search = () => {
    const [value, setValue] = useState('');
    const onInputChange = useOnInputSearchChange(setValue);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { language } = i18n;

    useEffect(() => {
        dispatch(setLanguage(language));
    }, [dispatch, language]);

    return (
        <Wrapper>
            <Input
                value={value}
                onChange={({ target }) => onInputChange(target.value)}
                placeholder={t('wyszukaj krążek')}
            />
            {value.length > 0 ? <RemoveIcon onClick={() => onInputChange('')} /> : <Icon />}
        </Wrapper>
    );
};

export default Search;
