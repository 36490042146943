import { Container } from './styled';
import HashInput from './HashInput';
import PdfExportButton from './PdfExportButton';
import ImageExportButton from './ImageExportButton';
import EmailInput from './EmailInput';

const Export = ({ isActive }) => (
    <Container isactive={isActive}>
        <HashInput />
        <EmailInput />
        <PdfExportButton />
        <PdfExportButton emptyBoardExport />
        <ImageExportButton />
    </Container>
);

export default Export;
