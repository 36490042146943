import axios from 'axios';

const fetchFromServerApi = async ({ path }) => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}${path}`);
    return response.data;
};

export const getDisks = async () => fetchFromServerApi({ path: '/api/disks' });
export const getBoard = async (hash) => fetchFromServerApi({ path: `/api/board/hash/${hash}` });
export const getTasks = async () => fetchFromServerApi({ path: '/api/tasks' });

const postToServerApi = async ({ path, body }) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}${path}`, body);
    return response.data;
};

export const postBoard = async (body) => postToServerApi({ path: '/api/board', body });

export const postEmail = async (body) => postToServerApi({ path: '/email', body });
