import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectDiskById } from '../../../../Common/disks/disksSlice';
import Translation from './Translation';

const StyledTranslations = styled.div`
    background-color: white;
    border: 1px solid black;
    padding: 10px;

    @media (max-width: 1650px) {
        padding: 4px;
    }
`;

const Translations = ({ diskId }) => {
    const disk = useSelector((state) => selectDiskById(state, diskId));

    return (
        <StyledTranslations onClick={(e) => { e.stopPropagation(); }}>
            {disk?.translations.map(({ name, language }, index) => (
                <Translation
                    key={index}
                    name={name}
                    diskId={diskId}
                    language={language}
                />
            ))}
        </StyledTranslations>
    );
};

export default Translations;
